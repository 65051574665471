import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import { Float, Gltf, Text3D,ContactShadows,OrbitControls } from "@react-three/drei";

import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import {Chart, ArcElement, Tooltip, Legend, Title} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import CanvasLoader from "../Loader";
import { useNavigate, useParams } from "react-router-dom";

const Building_s = () => {
 
  const [name, setName] = useState("");
  const navigate = useNavigate();
  let params = useParams();

    useEffect( () => {


      allfloors(params.ids);
      chart(params.ids);


      setName()
    }, []); 

    
    const [loading, setLoading] = useState(false);
    


    const [percentage, setPercentage] = useState([]);

    const chart= async (pro) => {
      setLoading(true);
    
      try {
      
      
        
        axios.get(`https://tower.future-swift.com/api/building_chart.php?b_id=`+pro)
        .then(res => {
       
          setPercentage(res.data.productlist.productdata[0]);
    
          setLoading(false);
        })
     
    } catch (error) { throw error;}    
  
  }
    


    const [floors, setFloors] = useState([]);

    const allfloors= async (pro) => {
      setLoading(true);
    
      try {
      
      
        
        axios.get(`https://tower.future-swift.com/api/floors.php?pro=`+pro)
        .then(res => {
       
          setFloors(res.data.productlist.productdata);
    
          setLoading(false);
        })
     
    } catch (error) { throw error;}    
  
  }
    
    
    


  Chart.register(ArcElement, Tooltip, Legend, Title);
  Chart.defaults.plugins.tooltip.backgroundColor = 'rgb(79, 109, 106)';
  Chart.defaults.plugins.legend.position = 'top';
  Chart.defaults.plugins.legend.title.display = true;
  Chart.defaults.plugins.legend.title.text = 'الأحصائيات';
  Chart.defaults.plugins.legend.title.color='#FFF';
  Chart.defaults.plugins.legend.labels.color='#FFF';

  const data = {
    labels: [
      'متاحة',
      
      'محجوزة',
      'مبيوعة',
     
    ],
    datasets: [{
      label: 'percentage',
      
       
      
      data: [percentage.available,percentage.selled1,percentage.selled],
    
      backgroundColor: [
        'rgb(0, 197, 0)',
        '#ff9800',
        '#ff0000'
      ],
 
      radius: '90%' ,  
      hoverOffset: 17,
      spacing:1,
      borderAlign:'center'
    }]
  };


    
    
  const handleOverIn3d = (e) => {
    document.body.style.cursor = 'move';
  }


  const handleOverIn = (e) => {
    document.body.style.cursor = 'pointer';
  }

  const handleOverOut = (e) => {
    document.body.style.cursor = '';
  }

  

  const INGREDIENT_SPACING = 0.2;
  const ingredientSpacing =  INGREDIENT_SPACING;
    var top=1;
    
    return (
      
      <div className='main'>
 

    
      {loading ? (
        <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
        <div >
<div style={{display:'flex',justifyContent:'center'}}>
 <img  style={{position:'absolute' , width: '100px', marginTop:'45px' ,display: 'block',zIndex:-1  }} src='.././logo2.png' />
        </div>
        <div style={{width:"50px",marginTop:70}}>
        <a href={"https://tower.future-swift.com/api/b_plan/"+percentage.plan_img}  className='imglink1'>
 <img className='planimg' style={{width:'40px',height:'40px',marginLeft:15}} src={"https://tower.future-swift.com/api/b_plan/"+percentage.plan_img}/>
 <p className='title' style={{width:'65px',fontSize:'12px'}} ><b>المخطط</b></p> 
 </a>
 </div>
 <div dir='rtl' style={{  position:'absolute' ,top:100, right:0}}>
 <a className="imglink1" title='Dashboard' target='_blank' href={percentage.location}>
 <img src=".././map.png" style={{width:'40px' ,marginRight:13}}/> 
 <p  className='title' style={{width:'60px',fontSize:'12px'}} ><b>الموقع</b></p> 
 </a>
</div>



        <div className="list" >
       
        <div style={{width:'300px' }}>
        <Doughnut  data={data} />


        <Nav class="navbar "   fill variant="underline">
        <Nav.Item >
<a className="b_icon" style={{color:'#fff'}} title='متاح' href=''> <strong>{percentage.available_count}</strong> <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/for-sale1.png"/> </a>
      </Nav.Item>
      <Nav.Item>
      <a className="b_icon" style={{color:'#fff'}} title='محجوز' href=''> <strong>{percentage.reserved_count}</strong> <img style={{width:'30px'}}  src="https://tower.future-swift.com/tool_img/reserved.png"/>
 </a>
      </Nav.Item>
      <Nav.Item>
      <a className="b_icon" style={{color:'#fff'}} title='مبيوع' href=''> <strong>{percentage.selled_count}</strong> <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/paid1.png"/> </a>
      </Nav.Item>
    
    </Nav>   
</div>




   
   
   <br></br>
   

   </div>

   

<div style={{display:'flex'}}>
<div style={{margin:'auto', zIndex:1}}>

<a href="#building" class="mouse_scroll">
<div class="mouse">
  <div class="wheel"></div>
</div>
<div>
  <span class="m_scroll_arrows unu"></span>
  <span class="m_scroll_arrows doi"></span>
  <span class="m_scroll_arrows trei"></span>
</div>
</a>

</div>
</div>
<Canvas id="building" style={{height:'100vh'}} camera={{ position: [0, 1.5, 5], fov: 40 }}>
     
   
        <Suspense fallback={<CanvasLoader />}>
       
      
        <group position-y={(-floors.length * ingredientSpacing) / 2}>
      <group>
      <hemisphereLight intensity={1} groundColor='black' />
       
    {floors.map((item,index)=>(
    
      top === 1 ? 
   /*   

    */

  
 
    <group  position-y={item.floor*ingredientSpacing}  >
  <group
   onPointerOver={(event) => handleOverIn()}
   onPointerOut={(event) => handleOverOut()}
            onClick={(e) => {
           //   window.history.pushState('Floor', '', `/building/${params.ids}/floor/${item.f_id}/${item.floor}`);
navigate(`/building/${params.ids}/floor/${item.f_id}/${item.floor}`);
      }}
          
          >
            <mesh position-x={0.7} position-y={0.042}>
              <planeGeometry args={[0.5, 0.16]} />
              <meshStandardMaterial color="white" opacity={0.5} transparent />
            </mesh>
  <Text3D
              font={'/fonts/Poppins_Bold.json'}
              scale={0.05}
              bevelSegments={3}
              bevelEnabled
              bevelThickness={0.001}
              position-x={0.6}
            >
              Floor {item.floor}
            </Text3D>
            </group>

        <Gltf
     
     onPointerOver={(event) => handleOverIn3d()}
     onPointerOut={(event) => handleOverOut()}
     src={'../assets/Floor1.glb'}
     scale={0.09}

        />
    
    {top+=1}
  </group>
    :
   
   
   /* 
    */

    <group  position-y={item.floor*ingredientSpacing}  >
  <group
    onPointerOver={(event) => handleOverIn()}
    onPointerOut={(event) => handleOverOut()}
             onClick={(e) => {
             // window.history.pushState('', '', `/building/${params.ids}/floor/${item.f_id}/${item.floor}`);
             navigate(`/building/${params.ids}/floor/${item.f_id}/${item.floor}`);
      }}
          
          >
            <mesh position-x={0.7} position-y={0.042}>
              <planeGeometry args={[0.5, 0.16]} />
              <meshStandardMaterial color="white" opacity={0.42} transparent />
            </mesh>
  <Text3D
              font={'/fonts/Poppins_Bold.json'}
              scale={0.05}
              bevelSegments={3}
              bevelEnabled
              bevelThickness={0.001}
              position-x={0.6}
            >
                Floor {item.floor}
            </Text3D>
            </group>

        <Gltf
       onPointerOver={(event) => handleOverIn3d()}
       onPointerOut={(event) => handleOverOut()}
          src={'../assets/Floor1.glb'}
          scale={0.09}

        />
    
    {top+=1}
  </group>


    ))}
    
    
    </group>
     
     </group>
    
    </Suspense>
         
         <OrbitControls
           enableZoom={true}
         
         />
       
        
       </Canvas>
      
    </div>
    
       
    )}
    
    
    
    <a style={{bottom:'70px', left:15 ,position:'fixed'}} href="/buildings" class="Btn1">
    <div class="sign1"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
  
  <div class="text1">خروج من البرج</div>

</a>


<div style={{display:'flex'}}>
<div style={{margin:'auto', zIndex:1}}>

<a href="#" class="button">
  <svg class="svgIcon" viewBox="0 0 384 512">
    <path
      d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z"
    ></path>
  </svg>
</a>


</div>
</div>         
      </div>
    )
    }
    



export default Building_s;









