
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { useSignOut } from "react-auth-kit";
import {useAuthUser} from 'react-auth-kit'
import Modal from 'react-bootstrap/Modal';
import Operation from "./operations/Operations";
import { Link } from "react-router-dom";
import axios from "axios";
const Header_in = (props) => {
   

 
  const logout = useSignOut();

  const auth = useAuthUser();  
    


  useEffect( () => {
    


  
    b_detail(props.list.ids);
   
  
  }, []); 
  


  const logOut =()=>{
    logout();
      localStorage.removeItem('key');
      localStorage.clear();
      
     
      window.location.reload(false);
  
  }





  const [building, setBuilding] = useState([]);

  const b_detail= async (pro) => {
 
  
    try {
    
 
      
      axios.get(`https://tower.future-swift.com/api/get_b_name.php?pro=`+pro)
      .then(res => {
     
        setBuilding(res.data.productlist.productdata[0]);

     
       
    
  
        
    

      })
   
  } catch (error) { throw error;}    

}





  

  var expand ="lg";


  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  


return (
  <div id='head'>

  
 
 <Navbar fixed="top"    expand={false}  bg="dark" data-bs-theme="dark">
          <Container fluid>
            <Navbar.Brand href={`/building/${localStorage.getItem("b_id")}`}>{ building.name}</Navbar.Brand>
            <Navbar.Brand >  <Link to='/buildings'> <img
           
           alt=""
           src={'https://tower.future-swift.com/logo2.png'}  
          
           width="50px"
      
         /></Link></Navbar.Brand>
            <Button onClick={handleShow} style={{border:'none',background:'transparent'}}><img style={{width:'30px',}} src="https://tower.future-swift.com/notification.png" /></Button>
              
            <Navbar.Toggle  className="bg-light" onClick={()=>setShow1(!show1)}  />
            <Navbar.Offcanvas
           show={show1} onHide={handleClose1} data-bs-theme="dark"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Future Swift
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
              <Link class="Btn1" to="/buildings">
  
  <div class="sign1"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
  
  <div class="text1" >خروج من البرج</div>

</Link>
                <Nav style={{marginTop:20}} className="justify-content-end flex-grow-1 pe-3">
               
                <Link  onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}} to={`/building/${localStorage.getItem('b_id')}/apart_sec`}>
                المخططات
                    </Link>
                 
                   <hr />
                   <Link  onClick={handleClose1} style={{borderRadius:10,width:'100%',backgroundColor:'GrayText',color:"white",textAlign:'center'}} to={`/building/${localStorage.getItem('b_id')}/apart_utl`}>
                   التشطيبات
                    </Link>
                  
               
                </Nav>
          
               
               

              </Offcanvas.Body>
              <button style={{bottom:10, left:15}} class="Btn">
  
  <div class="sign"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
  
  <div class="text" onClick={logOut}>Logout</div>


</button>
              <Navbar.Text dir="rtl" style={{margin:10}}>
            Developed by : <a href="#login">Future Swift</a>
          </Navbar.Text>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>



        <Modal show={show} style={{color:'#999'}} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>  اخر العمليات</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
       
        <div dir='rtl' >
  
    
  <div>    
  <Operation/>

        </div>
   
    
       </div>
        <Modal.Footer>

        </Modal.Footer>

        </Modal>
 
  </div>
)
  }

export default Header_in;









