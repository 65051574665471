import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';

import ReactToPrint from 'react-to-print';
import {  useParams } from "react-router-dom";


const Clients_item = () => {



    let params = useParams();


const [out, setOutput] = useState({
  name: '',
  any: '',
});





useEffect( () => {


    clients_special(params.so_id);
}, []); 


const [loading, setLoading] = useState(false);





const [special, setSpecial] = useState([]);
const clients_special = async (id) => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/clients_special.php?so_id=`+id)
    .then(res => {
        if(res.data.success===true){
            setSpecial(res.data.productlist.productdata);

        }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}

  

const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 


const [date, setDate] = useState(new Date());
var nf = new Intl.NumberFormat();
const tableRef = useRef(null);
var price =0;
var i=0;
return (
  
  <div >

  {loading ? (
   <div className="loader-container">
   
   <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
     <div class="spinner">
<div></div>
<div></div>
<div></div>
<div></div>
<div></div>
<div></div>
</div>
     </div>
     
   ) : ( 

     <div style={{backgroundColor:'white',fontFamily:'sans-serif'}} dir='rtl'>


<div  >


<br></br>
<br></br>



<ReactToPrint
     trigger={() => <button style={{background:'transparent',border:'none',margin:'30px'}} >    <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' /> </button>}
     content={() => tableRef.current}
   />


<br></br>
<div style={{width:'100%',marginBottom:100}} ref={tableRef}  dir='rtl'>



<div className='flex' style={{width:'99%',zIndex:1,placeItems:'center',marginLeft:'0.5%',marginRight:'0.5%' ,backgroundColor:'ButtonHighlight'}}  dir='ltr'>


<div  style={{width:'33%',margin:'10px'}}>
<img  style={{width:'70px' }} src='../../.././logo2.png' />
</div>

<div  style={{width:'33%'}}>
<h4 style={{color:'black',backgroundColor:'transparent',textAlign:'center'}}> طلب تشطيب  {params.so_id}</h4>
</div>


<div  style={{width:'33%'}}>
<h6 style={{color:'black',textAlign:'center'}}>{date.toDateString()}</h6>
</div>

</div>
   <hr  style={{color:'black'}}></hr> 

<ol  style={{ width:'100%', color:'black',zIndex:1,fontFamily:'sans-serif'}} >

   {special.map(item => (


     <div>
     {
i === 0 ?   
<div>  

<h5 style={{color:'black',backgroundColor:'ButtonHighlight',textAlign:'center'}}> برج  [{item.building}] || شقة  ({(Number(item.floor)*100)+Number(item.apart_num)})</h5>

</div>

:'' }
<li style={{padding:'20px',backgroundColor:'ButtonHighlight' }}>تعديل من نوع <strong>({item.c_name})</strong> 
     {' '} ل  <strong> [ {item.sc_id >0 ? item.sc_name : "الكل"} ]</strong>  التشطيب  نوع <strong>({item.u_name})</strong>
 
{Number(item.id) > 0 ?
<div >
<strong > 
<br></br>

A : ({item.a_ >0 ?  item.a :"اخرى" }) || ملاحظة : [ {item.a_d} ]
<br></br>
B : ({item.b_ >0 ?  item.b :"اخرى" }) || ملاحظة : [ {item.b_d} ]
<br></br>
C : ({item.c_ >0 ?  item.c :"اخرى" }) || ملاحظة : [ {item.c_d} ]
<br></br>
D : ({item.d_ >0 ?  item.d :"اخرى" }) || ملاحظة : [ {item.d_d} ]
<br></br>
E : ({item.e_ >0 ?  item.e :"اخرى" }) || ملاحظة : [ {item.e_d} ]
</strong> 



<br></br>

<br></br>

<div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>B</h4>

<strong>({item.b_ >0 ?  item.b :"اخرى" })

</strong>



</div>
</div> 
<br /><br />

<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px',  margin:"25px 10px",textAlign:'right'}} >
<h4>C</h4>



<strong>({item.c_ >0 ?  item.c :"اخرى" })

</strong>
</div>

<div style={{border:'3px #000 solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"15px 10px" ,textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>E</h4>

<strong>({item.e_ >0 ?  item.e :"اخرى" })
</strong>

   <div style={{display:'flex',marginTop:'80px',justifyContent:'center'}}>
<img src='../../../../../../../.././plan_door2.png' style={{width:'30px',height:'30px'}} />
</div> 
</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'left'}} >
<h4 style={{textAlign:'left'}}>A</h4>

<strong>({item.a_ >0 ?  item.a :"اخرى" })

</strong>

</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>D</h4>

<strong>({item.d_ >0 ?  item.d :"اخرى" })

</strong>
</div>
</div> 

<br /><br />




</div>






</div>


: <strong> الى {item.su_id >0 ?  item.su_name :"اخرى"}</strong>
 }    
 
 - 
 ملاحظة عامة : <strong>[{item.detail === '' ? 'لا يوجد' :item.detail}]</strong> <br />- 

 فارق سعر : <strong>{item.price} $</strong>
     

<br />
 

 
{item.pic !== '' ?
           
           
       <div>
 - صورة : 
 
     
 <img src={'https://tower.future-swift.com/api/special/'+item.pic} style={{width:'80px',margin:10}} />
 </div> 
 :''
}
     </li>
     <hr></hr> 

     
<td className='hide' >{price += Number(item.price) } </td> 
<td className='hide' >{i += 1 } </td> 


{
i === special.length ? 
<div  style={{width:'100%',padding:'20px',marginBottom:'80px'}}  dir='ltr'>


<h5 style={{width:'fit-content',zIndex:10, position:'absolute',right:'20px'}}>توقيع العميل  ({item.client})</h5> 




<h5 style={{width:'fit-content',zIndex:10, position:'absolute',left:'20px'}}>أجمالي فارق السعر : $ {nf.format(price)}</h5>
</div>

:''
}

</div>
     ))}
   
   </ol>


</div>

<br></br>  

</div>
      

</div>


)}




</div>
)
}


export default Clients_item;









