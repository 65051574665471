import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {  useParams } from "react-router-dom";

import Design from './Design';

import {useAuthUser} from 'react-auth-kit'
const Add_group = () => {
  let params = useParams();

  const auth = useAuthUser();  

const [emp, setEmp] = useState({});
const [emp1, setEmp1] = useState({});
const [out, setOutput] = useState({
  name: '',
  any: '',
});





useEffect( () => {

    cat();
 
  
}, []); 


const [loading, setLoading] = useState(false);


const cat = async () => {


  try {
   
    axios.get(`https://tower.future-swift.com/api/categories.php`)
    .then(res => {
     
      setEmp(res.data.productlist.productdata);
     

    })
  } catch (error) { throw error;}    

}





const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 
const onChangeValue1 = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });
  const value = e.target.value;
  setEmp1([]);
  subcat(value);
  
} 



const subcat = async (cat) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/subcat.php?cat=`+cat)
    .then(res => {
     
    
      setEmp1(res.data.productlist.productdata);
      
     
    })
  } catch (error) { throw error;}    

}





const add = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
   
    formData.append("name",out.name); 
   


    axios.post(`https://tower.future-swift.com/api/add_group.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};


return (
  
  <div >

     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
<div>
 


<div className="list" style={{marginTop:90,marginBottom:50}}>

   
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     

     
     
         <p  class="title">  اضافة مجموعة جديدة </p>
     
          
             <hr></hr>
<form onSubmit={add}>


<div dir='' class="form-group">



<label htmlFor="_name">اسم المجموعة:</label>

  <input
      type="text"
      id="_name"
      name="name"
      min={1}
      onChange={onChangeValue}
      placeholder="Enter Name"
      autoComplete="off"
      required
    />
    &emsp;
 <input type="submit" value="اضافة" />
   





</div>







</form>
</div>
<br></br>
</div>





    
</div>)}

<Design/>



  </div>
)
}


export default Add_group;









