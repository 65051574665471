import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {  useParams } from "react-router-dom";
import Header from '../Header';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {useAuthUser} from 'react-auth-kit'
import Manage_buildings from './Manage_buildings';


const Add_building = () => {

  const auth = useAuthUser();  


const [out, setOutput] = useState({

  name:'',
  address:'',
  location:'',
  floors:0,
  apart:0,



});





useEffect( () => {

   
 
  
}, []); 


const [loading, setLoading] = useState(false);






const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 


const [file,setFile] = useState("");
  
const setimgfile = (e)=>{
  setFile(e.target.files[0])
}


const add = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
    formData.append("name",out.name); 
    formData.append("address",out.address); 
    formData.append("location",out.location); 
    formData.append("floors",out.floors); 
    formData.append("apart",out.apart); 
    formData.append("photo",file); 
    axios.post(`https://tower.future-swift.com/api/add_building.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
     // Navigate("/");
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};


return (
  
  <div >

     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
<div>
 

   
        <div className="list" style={{marginTop:'90px',marginBottom:'50px', marginLeft:20,marginRight:20}}>
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     
         <p  class="title"> اضافة برج جديد </p>
          
             <hr></hr>

  <Form dir="RTL"  style={{width:'90%'}} onSubmit={add}>
  <div dir='' class="form-group">
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>اسم البرج</Form.Label>
          <Form.Control name='name' onChange={onChangeValue} required type="text" placeholder="اسم البرج" />
        </Form.Group>
        <hr></hr>
     
      <Form.Group className="mb-3" controlId="formGridAddress1">
        <Form.Label>العنوان</Form.Label>
        <Form.Control name='address' onChange={onChangeValue} required placeholder="صنعاء - شارع حده ...الخ" />
      </Form.Group>
      <hr></hr>
      <Row className="mb-2">
      <Form.Group className="mb-3" controlId="formGridAddress2">
        <Form.Label>الموقع</Form.Label>
        <Form.Control name='location' onChange={onChangeValue} type='text' placeholder="رابط الموقع من  Google Map" />
      </Form.Group>
      <a target='_blank' href='https://maps.google.com/'><Button variant="outline-success" style={{width:"200px"}}>Google Map</Button></a>
    
</Row>
<hr></hr>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="formGridCity">
          <Form.Label>الأدوار</Form.Label>
          <Form.Control name='floors' onChange={onChangeValue} required type="number"/>
        </Form.Group>

        <Form.Group as={Col} controlId="formGridState">
          <Form.Label>شقق / في الدور</Form.Label>
          <Form.Control name='apart' onChange={onChangeValue} required type="number"/>
        </Form.Group>


      </Row>
<hr></hr>
<Form.Group controlId="formFileSm" className="mb-3">
        <Form.Label>صورة المخطط</Form.Label>
        <Form.Control onChange={setimgfile} required type="file"  accept="image/*" size="sm" />
      </Form.Group>
      <hr></hr>
      <Button type="submit">
        اضافة
      </Button>
      </div>







</Form>
</div>
</div>
</div>)}

<br></br>


<Manage_buildings/>
  </div>
)
}


export default Add_building;









