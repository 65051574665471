import React, {useEffect,useState,useContext } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import {  useParams } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Header_in from '../Header_in';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {useAuthUser} from 'react-auth-kit'
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
const Getapartment = () => {
  const auth = useAuthUser();  
 

  let params = useParams();
  
  useEffect( () => {

 
 clients()
 get_old_special(params.apart_id)
special_count(params.apart_id);
    categories();
    reserve(params.apart_id);
paid(params.apart_id);
get_special(params.apart_id);
get_special_it(params.apart_id);

}, []); 

const [loading, setLoading] = useState(false);


const [cat, setCat] = useState([]);

const categories = async () => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get(`https://tower.future-swift.com/api/categories.php`)
    .then(res => {
      if(res.data.success==true){
        setCat(res.data.productlist.productdata);
        setLoading(false);
  if(Number(params.d_id) > 0){
    subcat(params.d_id);
  }

      }
     
     
      
    })
  } catch (error) { throw error;}    
 
}





const [sub, setSub] = useState([]);

const subcat = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://tower.future-swift.com/api/apartment.php?d_id='+id+'&ad_id='+params.ad_id)
    .then(res => {
      
      setSub(res.data.productlist.productdata);
      utilities();
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}








const [all_special, setAll_special] = useState([]);

const special_count = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://tower.future-swift.com/api/special_count.php?apart_id='+id)
    .then(res => {
      if(res.data.success===true){
        setAll_special(res.data.productlist.productdata);
        setLoading(false);
      }else{
        setLoading(false);

      }
    
     
     
    })
  } catch (error) { throw error;}    
 
}
















const [utl, setUtl] = useState([]);

const utilities = async () => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/utilities.php`)
    .then(res => {
     
    
      setUtl(res.data.productlist.productdata);
      if(Number(params.ad_id) > 0){
    //Subutl(params.ad_id);
    }
     
    })
  } catch (error) { throw error;}    

}


/*
const [subutl, setSubutl] = useState([]);

const Subutl = async (ad_id) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/cat_utl.php?ad_id=`+ad_id)
    .then(res => {
     
    
     setSubutl(res.data.productlist.productdata);
      
     
    })
  } catch (error) { throw error;}    

}
*/


  const options =[
    {value:"1", label:"شمال"},
    {value:"2", label:"جنوب"},
    {value:"3", label:"شرق"},
    {value:"4", label:"غرب"},
    {value:"5", label:"شمال شرق"},
    {value:"6", label:"شمال غرب"},
    {value:"7", label:"جنوب شرق"},
    {value:"8", label:"جنوب غرب"},
    {value:"9", label:"شرقي شمال "},
    {value:"10", label:"غربي شمال"},
    {value:"11", label:"شرقي جنوبي"},
    {value:"12", label:"غربي جنوبي"},
  ];







  const onChangeValue = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
  
    
  } 
  const onChangeValue1 = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
   
 
  } 
  
  const onChangeValue2 = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
   
   getsubutl(e.target.value);
    
  } 
  
const [sub_utl, setSub_utl] = useState([]);

const getsubutl = async (utl) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/subutl.php?utl=`+utl)
    .then(res => {
     
      setSub_utl([]);
      setSub_utl(res.data.productlist.productdata);
      
     
    })
  } catch (error) { throw error;}    

}





const [ispaid, setIspaid] = useState([]);

const paid = async (a_id) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/is_paid.php?a_id=`+a_id)
    .then(res => {
     
      setIspaid([]);
      setIspaid(res.data.productlist.productdata[0]);
      
     
    })
  } catch (error) { throw error;}    

}










const add_price = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("price",out.price); 
    formData.append("apart_id",params.apart_id); 

    axios.post(`https://tower.future-swift.com/api/add_price.php`, formData)
    .then(res => {
      setIspaid([]);
      paid(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




const add_buyer = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("name",out.name); 
    formData.append("pay",out.paid); 
    formData.append("sell_num",out.sell_num); 
    formData.append("client",out.client); 
    formData.append("any",out.any); 
    formData.append("note",out.note); 
    formData.append("photo",file); 
    formData.append("apart_id",params.apart_id); 

    axios.post(`https://tower.future-swift.com/api/add_buy.php`, formData)
    .then(res => {
      setIspaid([]);
      paid(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




const upd_buyer = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 

    formData.append("pay",out.paid); 
    formData.append("sell_num",out.sell_num); 
    formData.append("sell_id",ispaid.sell_id); 
    formData.append("paid_id",ispaid.paid_id);

    axios.post(`https://tower.future-swift.com/api/update_sell.php`, formData)
    .then(res => {
      setIspaid([]);
      paid(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




const add_payment = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
  
    formData.append("pay",out.paid); 
    formData.append("client",ispaid.buyer_id); 
    formData.append("apart_id",params.apart_id); 

    axios.post(`https://tower.future-swift.com/api/add_payment.php`, formData)
    .then(res => {
      setIspaid([]);
      //paid(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};


const upd__reserve = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
 
 
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("name",out.name); 
    formData.append("pay",out.paid); 
    formData.append("photo",file); 
    formData.append("client",out.client); 
    formData.append("any",out.any); 


    formData.append("r_id",is_reserve.r_id); 
    formData.append("paid_id",is_reserve.paid_id); 

    axios.post(`https://tower.future-swift.com/api/update_reserve.php`, formData)
    .then(res => {
      setIs_reserve([]);
     // reserve(params.apart_id);
     setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};


const add_reserve = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("name",out.name); 
    formData.append("pay",out.paid); 
    formData.append("photo",file); 
    formData.append("client",out.client); 
    formData.append("any",out.any); 
   // formData.append("s_id",ispaid.sell_id); 
    formData.append("apart_id",params.apart_id); 

    axios.post(`https://tower.future-swift.com/api/add_reserve.php`, formData)
    .then(res => {
      setIs_reserve([]);
     // reserve(params.apart_id);
     setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




const [is_reserve, setIs_reserve] = useState([]);

const reserve = async (a_id) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/is_reserved.php?a_id=`+a_id)
    .then(res => {
     
      setIs_reserve([]);
      setIs_reserve(res.data.productlist.productdata[0]);
      
     
    })
  } catch (error) { throw error;}    

}







const [order, setOrder] = useState([]);




const get_special = async(a_id) => {
  setLoading(true);
   
  try {
   

    axios.get(`https://tower.future-swift.com/api/get_special.php?apart_id=`+a_id)
    .then(res => {
  
      setOrder([]);
      setOrder(res.data.productlist.productdata[0]);
          setLoading(false);
    
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





const add_special = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 


    formData.append("buy_id",ispaid.buyer_id); 
    formData.append("apart_id",params.apart_id); 



    axios.post(`https://tower.future-swift.com/api/add_special.php`, formData)
    .then(res => {
      
    get_special(params.apart_id);

          setLoading(false);
      alert(res.data.msg);
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





const add_in = async(event) => {
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 


    formData.append("buy_id",ispaid.buyer_id); 
    formData.append("apart_id",params.apart_id); 



    axios.post(`https://tower.future-swift.com/api/add_special.php`, formData)
    .then(res => {
      
    get_special(params.apart_id);

          setLoading(false);
      alert(res.data.msg);
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





const [order1, setOrder1] = useState([]);




const get_special_it = async(a_id) => {
  setLoading(true);
  setOrder1([]);
  try {
   

    axios.get(`https://tower.future-swift.com/api/get_special_item.php?apart_id=`+a_id)
    .then(res => {
  if(res.data.success === true){

    setOrder1(res.data.productlist.productdata);
    setLoading(false);
  }else{
    setLoading(false);
  }
    
     
     
    
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




 


const [order_old1, setOrder_old1] = useState([]);

const get_old_special = async(a_id) => {
  setLoading(true);
   
  try {
   

    axios.get(`https://tower.future-swift.com/api/get_old_special.php?apart_id=`+a_id)
    .then(res => {
      if(res.data.success=true){
      setOrder_old1([]);
      setOrder_old1(res.data.productlist.productdata);
          setLoading(false);
        }else{
          setOrder_old1([]);
          setLoading(false);
        }
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};








function Custom_Toggle({ children, eventKey }) {

  const decoratedOnClick = useAccordionButton(eventKey, () =>
  
  get_old_special_it(eventKey)
  );

  return (
    <Button
     variant="outline-dark"
   style={{width:'100%'}}
      onClick={decoratedOnClick}
    >
      {children}
    </Button>
  );
}




const [order_old, setOrder_old] = useState([]);
const get_old_special_it = async(o_id) => {
  setSpin(true)
   
  try {
   

    axios.get(`https://tower.future-swift.com/api/get_old_special_item.php?o_id=`+o_id)
    .then(res => {
  
      if(res.data.success=true){
        setOrder_old([]);
        setOrder_old(res.data.productlist.productdata);
        setSpin(false);
      }else{
        setOrder_old([]);
        setSpin(false);
      }
    
     
    
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};




const conform = async(o_id) => {
  setLoading(true);
   
  try {
   

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("o_id",o_id); 
 
    axios.post(`https://tower.future-swift.com/api/conform.php`, formData)
    .then(res => {
    
          setLoading(false);
      alert(res.data.msg);
      
     window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





const [file,setFile] = useState("");
  
const setimgfile = (e)=>{
  setFile(e.target.files[0])
}



const add_special_item = async(o_id) => {
  setLoading(true);
   
  try {
   

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("cat_id",out.cat); 
    formData.append("sc_id",out.subcat); 

    formData.append("utl_id",out.utl); 
    formData.append("su_id",out.subutl);

    formData.append("detail",out.detail); 
    formData.append("photo",file); 


    formData.append("buy_id",ispaid.buyer_id); 
    formData.append("apart_id",params.apart_id); 
    formData.append("o_id",o_id); 
    formData.append("price",out.add_in_price);

    formData.append("a_su_id",out.a_su_id);
    formData.append("b_su_id",out.b_su_id);
    formData.append("c_su_id",out.c_su_id);
    formData.append("d_su_id",out.d_su_id);
    formData.append("e_su_id",out.e_su_id);

    formData.append("a",out.a);
    formData.append("b",out.b);
    formData.append("c",out.c);
    formData.append("d",out.d);
    formData.append("e",out.e);
 
    axios.post(`https://tower.future-swift.com/api/add_special_item.php`, formData)
    .then(res => {
     get_special_it(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
     // window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};





const [buyers, setBuyers] = useState([]);
const [type, setType] = useState('');

const clients = async () => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/clients.php?username=`+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
    setBuyers(res.data.productlist.productdata);
    setType(res.data.type)

        }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}



const handdle_change = (id) => {
  setOutput({
    ...out,
    ['client']:id
  }); 

}








  const [out, setOutput] = useState({
    cat: '',
    subcat: '',
    utl:"",
    subutl:"",
    detail:"",
    apart_id:params.apart_id,
  price:ispaid.price,
  name:"",
  note:"",
  paid:0,
  any:0,
  client:0,


a_su_id: '',
b_su_id: '',
c_su_id: '',
d_su_id: '',
e_su_id: '',

add_in_price:'0',

a: '',
b: '',
c: '',
d: '',
e: '',

sell_num: '',

  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  

  const [show2, setShow2] = useState(false);
  const handleSelect = () => setShow2(!show2);
  

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  

  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4= () => setShow4(true);
  

  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5= () => setShow5(true);
  


  const [open, setOpen] = useState(-1);
  const [open1, setOpen1] = useState(-1);
  const [open2, setOpen2] = useState(-1);



  const [show_res, setShow_res] = useState(false);
  const handleClose_res = () => setShow_res(false);
  const handleShow_res= () => setShow_res(true);

  const [spin, setSpin] = useState(false);
  
  const [subutl1, setSubutl1] = useState([]);
    
  const history_det = async (id,dd_id) => {
    setSubutl1([]);
       getsubutl(id);
    setSpin(true)
    
  

    try {
   
      axios.get(`https://tower.future-swift.com/api/cat_utl.php?utl_id=`+id+'&ad_id='+params.ad_id+'&dd_id='+dd_id)
      .then(res => {
       
        if(res.data.success===true){
          setSubutl1(res.data.productlist.productdata[0]);
       
          setSpin(false);
        }else{
          setSubutl1([]);
          setSpin(false);

          alert(res.data.msg)
        }
      
      
        
       
      })
    } catch (error) { throw error;}  
    
    }
  

    function CustomToggle({ children, eventKey,sc_id }) {

      const decoratedOnClick = useAccordionButton(eventKey, () =>
      
      history_det(eventKey,sc_id)
      );
    
      return (
        <Button
         variant="outline-dark"
       style={{width:'100%'}}
          onClick={decoratedOnClick}
        >
          {children}
        </Button>
      );
    }








    const [s_cat, setS_cat] = useState([]);
  const cat_special = async (id) => {
    setS_cat([]);
    setSpin(true)
    
  

    try {
   
      axios.get(`https://tower.future-swift.com/api/special_cat.php?cat_id=`+id+'&apart_id='+params.apart_id)
      .then(res => {
       
        if(res.data.success===true){
          setS_cat(res.data.productlist.productdata);
          setSpin(false);
        }else{
          
          setSpin(false);

          alert(res.data.msg)
        }
      
      
        
       
      })
    } catch (error) { throw error;}  
    
    }


    function CustomToggle1({ children, eventKey }) {
      const { activeEventKey } = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () =>
      

    cat_special(eventKey)
      );
      const isCurrentEventKey = activeEventKey === eventKey;
      return (
        <Button  onClick={decoratedOnClick} 
        style={{width:'fit-content',position:'',zIndex:10 }} 
       
        variant={isCurrentEventKey ? 'outline-warning' : 'warning' } 
  >
          {children}
        </Button>
      );
    }




    const [s_sub, setS_sub] = useState([]);
  const sub_special = async (id) => {
    setS_sub([]);
    setSpin(true)
    
  

    try {
   
      axios.get(`https://tower.future-swift.com/api/special_sub.php?sc_id=`+id+'&apart_id='+params.apart_id)
      .then(res => {
       
        if(res.data.success===true){
          setS_sub(res.data.productlist.productdata);
          setSpin(false);
        }else{
          
          setSpin(false);

          alert(res.data.msg)
        }
      
      
        
       
      })
    } catch (error) { throw error;}  
    
    }


    function CustomToggle2({ children, eventKey }) {
      const { activeEventKey } = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () =>
      

      sub_special(eventKey)
      );
      const isCurrentEventKey = activeEventKey === eventKey;
      return (
        <Button  onClick={decoratedOnClick} 
        style={{width:'fit-content',zIndex:10}} 
       
        variant={isCurrentEventKey ? 'outline-warning' : 'warning' } 
  >
          {children}
        </Button>
      );
    }



    

    function CustomToggle_d({ children, eventKey }) {
      const { activeEventKey } = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () =>
      

      sub_special(eventKey)
      );
      const isCurrentEventKey = activeEventKey === eventKey;
      return (
        <Button  disabled onClick={decoratedOnClick} 
        style={{width:'fit-content',zIndex:10}} 
       
        variant="outline-danger"
  >
          {children}
        </Button>
      );
    }



//
const onChangeValue_a = (id) => {
  setOutput({
    ...out,
    ['a_su_id']:id
  }); 
}



const onChangeValue_b = (id) => {
  setOutput({
    ...out,
    ['b_su_id']:id
  }); 
}


const onChangeValue_c = (id) => {
  setOutput({
    ...out,
    ['c_su_id']:id
  }); 
}


const onChangeValue_d = (id) => {
  setOutput({
    ...out,
    ['d_su_id']:id
  }); 
}



const onChangeValue_e = (id) => {
  setOutput({
    ...out,
    ['e_su_id']:id
  }); 
}








const add_buyer_res = async(event) => {
  
  setLoading(true);
   
  try {
    event.preventDefault();
    event.persist();

    var formData = new FormData();

 
   
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("name",out.name); 
    formData.append("pay",out.paid); 
    formData.append("sell_num",out.sell_num); 
    formData.append("client", is_reserve.buyer_id); 
    formData.append("any",'13'); 
    formData.append("note",out.note); 
    formData.append("photo",file); 
    formData.append("apart_id",params.apart_id); 

    axios.post(`https://tower.future-swift.com/api/add_buy.php`, formData)
    .then(res => {
      setIspaid([]);
      paid(params.apart_id);
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};






const confirm = async (id) => {
  setLoading(true);

try {
  
  var formData = new FormData();
  formData.append("username",auth().username); 
  formData.append("key",auth().key); 
  formData.append("sell_id",id); 
 
  axios.post(`https://tower.future-swift.com/api/confirm_buy.php`,formData)
  .then(res => {
      if(res.data.success===true){

        alert(res.data.msg)
        window.location.reload(false);
      }else{
        alert(res.data.msg)
      }
    
  setLoading(false);

  })
} catch (error) { throw error;}    

}



const delete_buy = async (id) => {
  setLoading(true);

try {
  
  var formData = new FormData();
  formData.append("username",auth().username); 
  formData.append("key",auth().key); 
  formData.append("sell_id",id); 
  formData.append("p_id",ispaid.paid_id); 
  axios.post(`https://tower.future-swift.com/api/delete_buy.php`,formData)
  .then(res => {
      if(res.data.success===true){

        alert(res.data.msg)
        window.location.reload(false);
      }else{
        alert(res.data.msg)
      }
    
  setLoading(false);

  })
} catch (error) { throw error;}    

}



var i =0;
var count =0;
var count1 =0;
var total=0;
var count2 =0;
return (
  
  <div>


 <img className='center1' style={{position:'fixed' , zIndex:0}} src='../../../../../../../.././logo2.png' />
  
  <br></br>
    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 


        <div   style={{marginTop:40 }} className=" position-relative">


<ToastContainer
          className=" p-3"
          style={{ zIndex: 1  }}
         position='top-end'
        >
          
           
            
{    Number(ispaid.paid_id) > 0 ?
            
            <Toast className='bg-dark'>
        
                <Toast.Header closeButton={false}>
                <img
                 style={{width:'32px',height:'32px'}}
                src="https://tower.future-swift.com/tool_img/paid1.png"
                className="rounded me-2"
                alt=""
              />
                <a style={{width:'120px',color:'#000'}} href={`https://tower.future-swift.com/api/client_id/${ispaid.id_img}`}><strong  className="me-auto">{ispaid.b_name}</strong></a>

{
  ispaid.confirm > 0 ? "":      
  <div>
<Button onClick={() => confirm(ispaid.sell_id)} variant="danger" type='submit'>
            تفعيل
          </Button> 

          <button onClick={() => delete_buy(ispaid.sell_id)} class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
</div>
}
                <Button onClick={handleShow3} style={{backgroundColor:'transparent',border:'none'}}>
                <img
                src="https://tower.future-swift.com/tool_img/edit.png"
                style={{width:'30px',height:'30px'}}
                className="rounded me-2"
                alt=""
              /></Button>
               
                <small>السعر: $ {ispaid.price} </small>

                </Toast.Header>
                <Toast.Body className='bg-dark'  style={{color:'red ', textAlign:'center'}}>تم البيع من قبل ({ispaid.username})</Toast.Body>
                 </Toast>
       
            
            
            
             :Number(is_reserve.r_id) > 0 ?
            <Toast className='bg-dark'>
            <Toast.Header closeButton={false}>
              <img
              style={{width:'32px'}}
                src="https://tower.future-swift.com/tool_img/reserved.png"
                className="rounded me-2"
                alt=""
              />
                 <a style={{width:'120px',color:'#000'}} href={`https://tower.future-swift.com/api/client_id/${is_reserve.id_img}`}><strong  className="me-auto">{is_reserve.b_name}</strong></a>

               {
                Number(ispaid.price) > 0 ?
                <div >
             

<Button onClick={handleShow3} style={{backgroundColor:'transparent',border:'none'}}>
<img
src="https://tower.future-swift.com/tool_img/edit.png"
style={{width:'30px',height:'30px'}}
className="rounded me-2"
alt=""
/></Button>

<small>السعر: $ {ispaid.price} </small>
</div>
                :  <Button variant="outline-dark" onClick={handleShow3}>   <small>  السعر؟ </small>   </Button>
               }
            
            </Toast.Header>
            <Toast.Body className='bg-dark'  style={{color:'orange ', textAlign:'center'}}>
            تم الحجز من قبل ({is_reserve.username})  
             </Toast.Body>
             </Toast>
       :
              <Toast className='bg-dark'>
              <Toast.Header closeButton={false}>
              <img
                style={{width:'32px'}}
                src="https://tower.future-swift.com/tool_img/for-sale1.png"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Available !</strong>
            
              {
                Number(ispaid.price) > 0 ?
                <div >
                <small>السعر: $ {ispaid.price} </small>
                <Button onClick={handleShow3} style={{backgroundColor:'transparent',border:'none'}}>
                <img
                src="https://tower.future-swift.com/tool_img/edit.png"
                style={{width:'30px',height:'30px'}}
                className="rounded me-2"
                alt=""
              /></Button>
             
</div>
                :    <Button variant="outline-dark" onClick={handleShow3}>   <small>  السعر؟ </small>   </Button>
               }

            </Toast.Header>
            <Toast.Body className='bg-dark'  style={{color:'green ', textAlign:'center'}}>
            متاحة  
             </Toast.Body>
             </Toast>
              }
              
          
           
         
        </ToastContainer>


        
  

        <Modal show={show} style={{color:'#999'}} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >طلبات خاصة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Tabs
        
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3" justify
    >
      <Tab eventKey="home"  title="اضافة طلب جديد">
      
 <div dir='rtl'>






{order.order_id > 0 ? ""


:

<Form.Label htmlFor="">اضافة طلب

<Button  variant="success" onClick={add_special}>
            Add
          </Button>
</Form.Label>

 }





 {order.order_id > 0 ? 

 <div  dir="RTL"  >


<Accordion defaultActiveKey="0" >
    <Accordion.Item eventKey="0">
      <Accordion.Header> طلب [{order.date}]
        </Accordion.Header>
      <Accordion.Body>
      <ol>
      {order1.map(item => (
        <div>
        <li>تعديل من نوع <strong>({item.c_name})</strong> 
        {' '} ل  <strong> [ {item.sc_id >0 ? item.sc_name : "الكل"} ]</strong>  التشطيب  نوع <strong>({item.u_name})</strong>
    
{Number(item.id) > 0 ?

<strong> 
<br></br>

A : ({item.a_ >0 ?  item.a :"اخرى" }) || ملاحظة : [ {item.a_d} ]
<br></br>
B : ({item.b_ >0 ?  item.b :"اخرى" }) || ملاحظة : [ {item.b_d} ]
<br></br>
C : ({item.c_ >0 ?  item.c :"اخرى" }) || ملاحظة : [ {item.c_d} ]
<br></br>
D : ({item.d_ >0 ?  item.d :"اخرى" }) || ملاحظة : [ {item.d_d} ]
<br></br>
E : ({item.e_ >0 ?  item.e :"اخرى" }) || ملاحظة : [ {item.e_d} ]
</strong> 

: <strong> الى {item.su_id >0 ?  item.su_name :"اخرى"}</strong>
    }    
    
    <br></br> 
    ملاحظة عامة : <strong>[{item.detail === '' ? 'لا يوجد' :item.detail}]</strong> ||

    فارق سعر : <strong>{item.price} $</strong>
        
    <button class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
        </li>
        <hr></hr>  
</div>
        ))}
      
      </ol>

      
 <div className='flex' >
 <div style={{width:'50%'}}>
 {
        order1.length > 0 ? 
        <Button variant="success" onClick={()  => conform(order.order_id)}>
            تفعيل
          </Button>
        :<Button variant="success" disabled onClick={()  => conform(order.order_id)}>
            تفعيل
          </Button>
      }
</div>
 <div dir='ltr' style={{width:'50%'}}>
<a href={`/clients/special/items/${order.order_id}`} className="b_icon" >
        <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' />
      </a>
      </div>
      </div>
    

      
      </Accordion.Body>
     
    </Accordion.Item>
  </Accordion>


  

</div>

: ""
 }

<hr></hr>


{order.order_id > 0 ? 
<Form id="container" dir="RTL" >


<Row className="mb-2">


<Form.Group as={Col} controlId="formGrid">
        <Form.Label>نوع العنصر:</Form.Label>
        {cat.length > 0 ? 
         <Form.Select id='_cat' name='cat' onChange={onChangeValue} required>
        <option value='' selected> Choose... </option>
          {cat.map(option => (
      
      <option  key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
        </Form.Select>

        :<Form.Select defaultValue="لا توجد بيانات">
        <option>لا توجد بيانات</option>
        </Form.Select>
        }
      </Form.Group>

     
<Form.Group as={Col}  controlId="formGrid">
        <Form.Label>العنصر:</Form.Label>
        {sub.length > 0 && out.cat >0 ?
         <Form.Select id='_subcat' name='subcat' onChange={onChangeValue} required>
        <option value='' selected> Choose... </option>
        <option value="0" > الكل  </option>
          {sub.map(option => ( option.cat_id === out.cat ?
      
      <option  key={option.sc_id} value={option.sc_id}>
        {option.name2}
      </option>
      :""
    ))}
        </Form.Select>

        :<Form.Select defaultValue="لا توجد بيانات">
        <option>لا توجد بيانات</option>
        </Form.Select>
        }
      </Form.Group>

 
    </Row>



    <Row className="mb-2">


<Form.Group as={Col} controlId="formGrid">
        <Form.Label>نوع التشطيب:</Form.Label>
        {cat.length > 0 ?
         <Form.Select id='_utl' name='utl' onChange={onChangeValue2} required>
        <option value='' selected> Choose... </option>
        {utl.map(option => (
      
      <option  key={option.utl_id} value={option.utl_id}>
        {option.name}
      </option>
    ))}
        </Form.Select>

        :<Form.Select defaultValue="لا توجد بيانات">
        <option>لا توجد بيانات</option>
        </Form.Select>
        }
      </Form.Group>
      </Row>
      <hr></hr>
{

  (out.cat ==='2' || out.cat ==='3'  ) && out.utl==='1'?




  <div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>B</h4>

<DatalistInput

style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_b(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<Form.Group style={{margin:5}} >
       
         <Form.Control  name='b' placeholder='ملاحظة' type='text' onChange={onChangeValue} required />
      </Form.Group>


</div>
</div> 
<br /><br />
<br /><br />
<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4>C</h4>

<DatalistInput

style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_c(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<Form.Group style={{margin:5}} >
       
         <Form.Control  name='c' placeholder='ملاحظة' type='text' onChange={onChangeValue} required />
      </Form.Group>

</div>

<div style={{border:'3px #000 solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>E</h4>

<DatalistInput

style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_e(item.id)}
items={  

  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<Form.Group style={{margin:5}} >
       
         <Form.Control  name='e' placeholder='ملاحظة' type='text' onChange={onChangeValue} required />
      </Form.Group>


      <br />

      <div style={{display:'flex',justifyContent:'center'}}>
<img src='../../../../../../../.././plan_door2.png' style={{width:'30px',height:'30px'}} />
</div> 
</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4 style={{textAlign:'left'}}>A</h4>

<DatalistInput
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_a(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

<Form.Group style={{margin:5}} >
       
         <Form.Control  name='a' placeholder='ملاحظة' type='text' onChange={onChangeValue} required />
      </Form.Group>

</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>D</h4>

<DatalistInput

style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_d(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<Form.Group style={{margin:5}} >
       
         <Form.Control  name='d' placeholder='ملاحظة' type='text' onChange={onChangeValue} required />
      </Form.Group>

</div>
</div> 

<br /><br />
<br /><br />



</div>

  :

  <Form.Group as={Col}  controlId="formGrid">
        <Form.Label>الى نوع:</Form.Label>
 {sub_utl.length > 0 && out.utl >0 ?  
 <Form.Select id='_subutl' name='subutl'  onChange={onChangeValue} required>
        <option value='' selected> Choose... </option>
<option value="0" > اخرى  </option>
{sub_utl.map(item => ( 
      
      <option  key={item.value} value={item.value}>
        {item.label}
      </option>
      
    ))}
  
        </Form.Select>
        
        :<Form.Select defaultValue="لا توجد بيانات">
        <option>لا توجد بيانات</option>
        </Form.Select>
        }
      </Form.Group>
}
     


 <hr></hr>
   
 <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label>فارق السعر:</Form.Label>
      <Form.Control name='add_in_price' min={0} type='number' onChange={onChangeValue} rows={3} />
    </Form.Group>

    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
      <Form.Label>الملاحظة:</Form.Label>
      <Form.Control as="textarea" name='detail' onChange={onChangeValue1} rows={3} />
    </Form.Group>

    
    <Row className="mb-2">

    <Form.Group controlId="formFileSm" className="mb-3">
        <Form.Label>اضافة صورة</Form.Label>
        <Form.Control type="file" size="sm" accept="image/*" onChange={setimgfile} />
      </Form.Group>
      </Row>
      <Button variant="primary" onClick={() => add_special_item(order.order_id)}>
            Add
          </Button>
</Form>

:''
}
<br></br>
</div>

      </Tab>
      <Tab eventKey="profile" title="الطلبات السابقة">
       


      <Accordion dir='rtl' defaultActiveKey="0">
   

      {order_old1.map(o => (
     <Card>
         <Card.Header>
           <Custom_Toggle eventKey={o.o_id}><strong>{i+=1} {o.date}  
       </strong>  <small style={{float:'right'}}>{o.username}</small>  <small style={{float:'left'}}> {o.date} </small></Custom_Toggle>
         </Card.Header>
         <Accordion.Collapse eventKey={o.o_id}>
         <Card.Body style={{width:'100%'}}>
 {
 spin ?  <Spinner animation="border" />
 :
 
 <ol>
      {order_old.map(item => (
        <div>
        <li>تعديل من نوع <strong>({item.c_name})</strong> 
        {' '} ل  <strong> [ {item.sc_id >0 ? item.sc_name : "الكل"} ]</strong>  التشطيب  نوع <strong>({item.u_name})</strong>
    
{Number(item.id) > 0 ?

<strong> 
<br></br>

A : ({item.a_ >0 ?  item.a :"اخرى" }) || ملاحظة : [ {item.a_d} ]
<br></br>
B : ({item.b_ >0 ?  item.b :"اخرى" }) || ملاحظة : [ {item.b_d} ]
<br></br>
C : ({item.c_ >0 ?  item.c :"اخرى" }) || ملاحظة : [ {item.c_d} ]
<br></br>
D : ({item.d_ >0 ?  item.d :"اخرى" }) || ملاحظة : [ {item.d_d} ]
<br></br>
E : ({item.e_ >0 ?  item.e :"اخرى" }) || ملاحظة : [ {item.e_d} ]
</strong> 

: <strong> الى {item.su_id >0 ?  item.su_name :"اخرى"}</strong>
    }    
    
    <br></br> 
    ملاحظة عامة : <strong>[{item.detail === '' ? 'لا يوجد' :item.detail}]</strong> ||

    فارق سعر : <strong>{item.price} $</strong>
        
     
        </li>
        <hr></hr>  
</div>
        ))}
      
      </ol>

 }

 <div className='flex' >
 <div style={{width:'50%'}}>
 <button class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
</div>
 <div dir='ltr' style={{width:'50%'}}>
<a href={`/clients/special/items/${o.o_id}`} className="b_icon" >
        <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' />
      </a>
      </div>
      </div>
        </Card.Body>
          
         </Accordion.Collapse>
       </Card>
      
       ))}
     
     </Accordion>
       


 


      </Tab>
    
    </Tabs>


         
    
         
        </Modal.Body>
        <Modal.Footer>
        {
          show2 ? <div>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleClose}>
            Print
          </Button>
          </div>
          :
          <div>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
          </div>

        }
         
        </Modal.Footer>
      </Modal>
   

        
     <ul>   

<br></br>
<br></br>
     <div className='container2' style={{marginTop:'110px'}}>
     
        <div className="list">


        <h1>  شقة رقم ({params.a_num})</h1>
        <br></br>
        {options.map((option,index)=>(
          option.value === params.dir ?

          <h2>  ({option.label})</h2>
          :""
        ))}

        <br ></br>
      {
        Number(params.d_id) >  0 ?

        <a href={"https://tower.future-swift.com/api/plan_img/"+params.img}  className='imglink1'>
 <img className='planimg' src={"https://tower.future-swift.com/api/plan_img/"+params.img}/>
 <p className='title'><b>المخطط</b></p> 
 </a>

 :<a href={`/apart_des/${params.as_id}/${params.apart_num}`} className="error" >
 <b>قم باضافة مخطط للشقق رقم {params.apart_num} ! </b>
 </a>



      } 
      
 <br></br>
 <br></br>

  <div className="container_apart" dir='rtl'>
{cat.map((cat,index)=>(
  


  
  <div>
  <td className='hide' >{all_special.map((item)=>(item.cat_id===cat.value ?
    <td className='hide' >{count+=1}</td>
    :null
    ))}</td>

  <td className='hide' >{sub.map((item,index)=>(item.cat_id===cat.value ?
    <td className='hide' >{total+=Number(item.count)}</td>
    :null
    
    ))}</td>
   
       
<Accordion >


{
count > 0 ? 
<CustomToggle1 eventKey={cat.value}>
<Badge bg="warning">{count}</Badge>
     </CustomToggle1>


  :
  <CustomToggle_d  eventKey={cat.value}>
<Badge bg="danger">0</Badge>
</CustomToggle_d>
} 



     <Accordion.Collapse  eventKey={cat.value}>
     <Card  className='bg-light'  style={{width:'400px',color:'#000',position:'',zIndex:11 }}>
{
spin ?  <Spinner animation="border" />
:

<div><Card.Header>  طلبات خاصة لل {cat.label}  </Card.Header>
<ol>

{s_cat.map(item => (
  <li>تعديل من نوع <strong>({item.c_name})</strong> 
        {' '} ل  <strong> [ {item.sc_id >0 ? item.sc_name : "الكل"} ]</strong>  التشطيب  نوع <strong>({item.u_name})</strong>
    
{Number(item.id) > 0 ?

<strong> 
<br></br>

A : ({item.a_ >0 ?  item.a :"اخرى" }) || ملاحظة : [ {item.a_d} ]
<br></br>
B : ({item.b_ >0 ?  item.b :"اخرى" }) || ملاحظة : [ {item.b_d} ]
<br></br>
C : ({item.c_ >0 ?  item.c :"اخرى" }) || ملاحظة : [ {item.c_d} ]
<br></br>
D : ({item.d_ >0 ?  item.d :"اخرى" }) || ملاحظة : [ {item.d_d} ]
<br></br>
E : ({item.e_ >0 ?  item.e :"اخرى" }) || ملاحظة : [ {item.e_d} ]
</strong> 

: <strong> الى {item.su_id >0 ?  item.su_name :"اخرى"}</strong>
    }    
    
    <br></br> 
    ملاحظة عامة : <strong>[{item.detail === '' ? 'لا يوجد' :item.detail}]</strong> ||

    فارق سعر : <strong>{item.price} $</strong>

<br></br>
<a style={{color:"#999999"}} href={'https://tower.future-swift.com/api/special/'+item.pic}>
<img src='../../../../../../../.././photo.png' style={{width:'30px',height:'30px'}} />
</a>
        </li>


    ))}
  
  </ol>
</div>


}


</Card>
      </Accordion.Collapse>
    
 

  </Accordion>


        <div className='cat'
    
     
       
     aria-controls="example-collapse-text"
     aria-expanded={open === index}
        
      >
   <img className='utilities' src={"https://tower.future-swift.com/api/elements/"+cat.img}/>
   <Button  onClick={() => setOpen(open === index ? -1 : index)} style={{width:'150px'}} variant="outline-dark"> 
   {cat.label} ({total}) 
  
    </Button>
    

      </div>
        <ol>
        <br></br>
      
      <Collapse in={open === index}>
        <div id="example-collapse-text">
        


        
        {sub.map((item,index)=>(
            item.cat_id === cat.value ? 
          
          
          <div>
    <td className='hide' >{all_special.map((all)=>(cat.value === all.cat_id && (all.sc_id ===item.sc_id || all.sc_id === '0' ) ?
    <td className='hide' >{count1+=1}</td>
    :null
    ))}</td>






          <li style={{display:'flex'}} className='elements'>

<Button variant="outline-light"
 onClick={() => setOpen1(open1 === index ? -1 : index)}
     aria-controls="example-collapse-text"
     aria-expanded={open1 === index}

>
({item.count}) {item.name2} 
       

  </Button>

     
            
<div >




<Accordion >


{
count1 > 0 && count>0 ?
<CustomToggle2 eventKey={item.sc_id}>
<Badge bg="warning">{count1}</Badge>
</CustomToggle2>
  :
''
    } 


    <Accordion.Collapse  eventKey={item.sc_id}>
 <Card  className='bg-light'  style={{ width:'400px',color:'#000',marginRight:'50px',position:'',zIndex:10 }}>
{
spin ?  <Spinner animation="border" />
:

<div><Card.Header>  طلبات خاصة لل {item.name2}  </Card.Header>
<ol>

{s_sub.map(s_sub => (
<div>
<li>تعديل من نوع <strong>({s_sub.c_name})</strong> 
        {' '} ل  <strong> [ {s_sub.sc_id >0 ? s_sub.sc_name : "الكل"} ]</strong>  التشطيب  نوع <strong>({s_sub.u_name})</strong>
    
{Number(s_sub.id) > 0 ?

<strong> 
<br></br>

A : ({s_sub.a_ >0 ?  s_sub.a :"اخرى" }) || ملاحظة : [ {s_sub.a_d} ]
<br></br>
B : ({s_sub.b_ >0 ?  s_sub.b :"اخرى" }) || ملاحظة : [ {s_sub.b_d} ]
<br></br>
C : ({s_sub.c_ >0 ?  s_sub.c :"اخرى" }) || ملاحظة : [ {s_sub.c_d} ]
<br></br>
D : ({s_sub.d_ >0 ?  s_sub.d :"اخرى" }) || ملاحظة : [ {s_sub.d_d} ]
<br></br>
E : ({s_sub.e_ >0 ?  s_sub.e :"اخرى" }) || ملاحظة : [ {s_sub.e_d} ]
</strong> 

: <strong> الى {s_sub.su_id >0 ?  s_sub.su_name :"اخرى"}</strong>
    }    
    
    <br></br> 
    ملاحظة عامة : <strong>[{s_sub.detail === '' ? 'لا يوجد' :s_sub.detail}]</strong> ||

    فارق سعر : <strong>{s_sub.price} $</strong>
        
       <Button style={{backgroundColor:'transparent',border:'none'}}>
                <img
                src="https://tower.future-swift.com/tool_img/edit.png"
                style={{width:'20px',height:'20px'}}
                className="rounded me-2"
                alt=""
              /></Button>
<br></br>
 <a style={{color:"#999999"}} href={'https://tower.future-swift.com/api/special/'+s_sub.pic}>
صورة
</a>
</li>
<hr></hr>  
</div>
))}

</ol>
</div>


}


</Card>
  </Accordion.Collapse>



</Accordion>







</div>      
         

     
             
          </li>
          <br></br>
          <Collapse in={open1 === index}>
       
          <div style={{width:'fit-content'}} className="e_content">
          <ol>

         
           

            <div>
 
    
            <Accordion style={{width:'300px'}} defaultActiveKey="0">
   
   {utl.map((utl,index)=>(
 <li>

 <td className='hide' >{all_special.map((all1)=>( cat.value === all1.cat_id &&  ( all1.sc_id ===item.sc_id || all1.sc_id === '0') && all1.utl_id ===utl.utl_id ?
    <td className='hide' >{count2+=1}</td>
    :null
    ))}</td>
     <Card style={{margin:10,}} >
     {
      count>0 && count1 >0 && count2>0 ?
      <Button style={{width:'fit-content'}} variant='outline-warning'>
     <Badge bg="warning">{count2}</Badge>
     </Button>
     :''
     }
    
   
         <Card.Header >
           <CustomToggle sc_id={item.dd_id} eventKey={utl.utl_id}><strong>{utl.name} </strong>
         
           </CustomToggle>
         </Card.Header>
         <Accordion.Collapse eventKey={utl.utl_id}>
         <Card.Body  style={{width:'350px',backgroundColor:'rgba(60, 60, 60, 0.8)'}}>
 {
 spin ?  <Spinner animation="border" />
 :





 <div  className="container2">

 <small style={{float:'left'}}>{subutl1.date}</small>
 <small >مستخدم : ({subutl1.u_name})</small>
<hr></hr>
 <ul>
{Number(subutl1.id) > 0 ?
  <div>

<div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>B</h4>


<strong>({  sub_utl.map(sub1 => (sub1.value === subutl1.b_su_id ? sub1.label  : '' ))}) </strong>
<br />



</div>
</div> 
<br /><br />

<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4>C</h4>


<strong>({  sub_utl.map(sub1 => (sub1.value === subutl1.c_su_id ? sub1.label  : '' ))}) </strong>
<br />


</div>

<div style={{border:'3px #000 solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"15px 10px" ,textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>E</h4>


<strong>({  sub_utl.map(sub1 => (sub1.value === subutl1.e_su_id ? sub1.label  : '' ))}) </strong>
<br />


      <br />
      <br />
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
<img src='https://tower.future-swift.com/tool_img/plan_door2.png' style={{width:'30px',height:'30px'}} />
</div> 
</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'left'}}>A</h4>


<strong>({sub_utl.map(sub1 => (sub1.value === subutl1.a_su_id ? sub1.label  : '' ))}) </strong>
<br />


</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>D</h4>


<strong>({  sub_utl.map(sub1 => (subutl1.d_su_id  === sub1.value ? sub1.label  : '' ))}) </strong>
<br />

</div>
</div> 

<br /><br />
<br /><br />



</div>
</div>


:

<li>النوع: {subutl1.name}</li>

}
<br></br>

<li>الملاحظة: {subutl1.note}</li>
<br></br>
<li> <a style={{color:"#999999"}} href={'https://tower.future-swift.com/api/utilities/'+subutl1.img}>
 <img style={{width:'30px'}} src="../../../../../../../.././photo.png" />
 عرض 
 </a></li>
 <br></br>
</ul>

 </div>



}
        </Card.Body>
          
         </Accordion.Collapse>
       </Card>
       <td className='hide' >{count2=0}</td>
       </li>
         ))}
      
     
     </Accordion>
   
</div>


     
        </ol>
        </div>

          </Collapse>
          <br></br>
          <td className='hide' >{count1=0}</td>
        
          </div> 
         :""


          ))}
        
        </div>
      </Collapse>
        
       
          </ol>
     
          <td className='hide' >{count=0}</td>
          <td className='hide' >{total=0}</td>
</div>
   ))}


   </div>

</div>
  </div>
</ul>


<br></br>
        </div>
      )}









      <Modal show={show5} style={{color:'#999'}} backdrop="static" onHide={handleClose5}>
        <Modal.Header closeButton>
        <Modal.Title> توريد لحساب <mark style={{backgroundColor:'yellow'}}>{ispaid.b_name}</mark> </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form  id="container" dir="RTL" >

        <Form.Label htmlFor="paid">المبلغ</Form.Label>
      <Form.Control
      id='paid'
      name='paid'
      type="number"
      required

      onChange={onChangeValue1}
       min={0}
      />
      
    
      </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose5}>
            Close
          </Button>
          <Button variant="success" onClick={add_payment}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>



      <Modal show={show3} style={{color:'#999'}} backdrop="static" onHide={handleClose3}>
        <Modal.Header closeButton>
        <Modal.Title>تحديد السعر</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form  id="container" dir="RTL" >

        <Form.Label htmlFor="price">السعر</Form.Label>
      <Form.Control
      id='price'
      name='price'
      type="number"
      value={out.price}
      onChange={onChangeValue1}
       min={0}
      />
      
    
      </Form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose3}>
            Close
          </Button>
          <Button variant="success" onClick={add_price}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    



      <Modal show={show4} style={{color:'#999'}} backdrop="static" onHide={handleClose4}>
        <Modal.Header closeButton>
        <Modal.Title> بيع الشقة </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form  id="container" dir="RTL" onSubmit={
  Number(ispaid.paid_id) > 0 ? 
  upd_buyer
           : Number(is_reserve.r_id) > 0 ?
         add_buyer_res
          :
          add_buyer} >

   

{

  Number(ispaid.paid_id) > 0 ? 

  <div>
  <Form.Label htmlFor="name"> العميل  </Form.Label>
      <Form.Control
      id='name'
      name='name'
      type="text"
    readOnly
    value={ispaid.b_name}
   

      />
    
    </div>

:Number(is_reserve.r_id) > 0 ?

<div>
  <Form.Label htmlFor="name"> العميل الحاجز </Form.Label>
      <Form.Control
      id='name'
      name='name'
      type="text"
    readOnly
    value={is_reserve.b_name}
   

      />
    
    </div>


: 
<div>
<div onChange={onChangeValue1}>
<label>عميل جديد 
<input type="radio" defaultChecked value={0} name="any"  /> 
</label>
&emsp;
&emsp;
<label>عميل سابق 
       <input type="radio" value={1} name="any" />  
       </label>
      </div>
      
{out.any > 0 ? 


<Form.Group as={Col} controlId="formGrid">

<DatalistInput

    placeholder="Search and choose..."
    label="العملاء:"
    onSelect={(item) => handdle_change(item.id)}
    items={  buyers.map(option => (
      type==='1'?
      { id: option.buyer_id, value:option.name }
      :
      { id: option.buyer_id, value:option.buyer_id }
      ))

    }
  />


</Form.Group>
   
       

  :   <div>
  <Form.Label htmlFor="name">اسم المشتري</Form.Label>
      <Form.Control
      id='name'
      name='name'
      type="text"
    
      onChange={onChangeValue1}
      required
      />
     <Form.Label htmlFor="name">صورة البطاقة</Form.Label>
     <Form.Control type="file" size="sm" accept="image/*" onChange={setimgfile} />
    </div>
}

</div>
}



<Form.Label htmlFor="paid"> رقم العقد </Form.Label>
      <Form.Control
   
      name='sell_num'
      type="text"
      required

      onChange={onChangeValue1}
      
      />
      {
        Number(ispaid.paid_id) > 0 ? 
<mark>البيانات السابقة : <strong>{ispaid.sell_no}</strong></mark>
        :''
      }
     
<br></br>
<Form.Label htmlFor="paid">المبلغ المدفوع</Form.Label>
      <Form.Control
      id='paid'
      name='paid'
      type="number"
      required

      onChange={onChangeValue1}
       min={0}
      />

<Form.Label htmlFor="note"> ملاحظة </Form.Label>
      <Form.Control
      id='note'
      name='note'
      type="text"
      required

      onChange={onChangeValue1}
      
      />

{
        Number(ispaid.paid_id) > 0 ? 
<mark>البيانات السابقة : <strong>{ispaid.paid}</strong>$</mark>
        :''
      }
     
<br></br>
<br></br>

{  Number(ispaid.paid_id) > 0 ? 
            <Button variant="warning" type='submit'>
            Update
          </Button>
           : Number(is_reserve.r_id) > 0 ?
          <Button variant="success" type='submit'>
            Save
          </Button>
          :
          <Button variant="success" type='submit'>
            Save
          </Button>}

      </Form>
      <button class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose4}>
            Close
          </Button>
        
        </Modal.Footer>
      </Modal>





      <Modal show={show_res} style={{color:'#999'}} backdrop="static" onHide={handleClose_res}>
        <Modal.Header closeButton>
        <Modal.Title> حجز الشقة </Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <Form  id="container" dir="RTL" onSubmit={ Number(is_reserve.r_id) > 0 ?
    upd__reserve
        :   add_reserve
         } >



<div>
        <div onChange={onChangeValue1}>
<label>عميل جديد 
<input type="radio" defaultChecked value={0} name="any"  /> 
</label>
&emsp;
&emsp;
<label>عميل سابق 
       <input type="radio" value={1} name="any" />  
       </label>
      </div>
      <br></br>
{
out.any > 0 ? 
<div>

 

<Form.Group as={Col} controlId="formGrid">

<DatalistInput

    placeholder="Search and choose..."
    label="العملاء:"
    onSelect={(item) => handdle_change(item.id)}
    items={  buyers.map(option => (
      type==='1'?
      { id: option.buyer_id, value:option.name }
      :
      { id: option.buyer_id, value:option.buyer_id }
      ))

    }
  />


</Form.Group>
   
          </div>

:   <div>
  <Form.Label htmlFor="name"> بأسم </Form.Label>
      <Form.Control
      id='name'
      name='name'
      type="text"
    
      onChange={onChangeValue1}
      required
      />
        <Form.Label htmlFor="name">صورة البطاقة</Form.Label>
     <Form.Control type="file" size="sm" accept="image/*" onChange={setimgfile} />
    </div>
}


{
        Number(is_reserve.r_id) > 0 ? 
<mark>البيانات السابقة : <strong>{is_reserve.b_name}</strong></mark>
        :''
      }
      <br></br>
<br></br>
</div>
        

<Form.Label htmlFor="paid">المبلغ المدفوع</Form.Label>
      <Form.Control
      id='paid'
      name='paid'
      type="number"
      required

      onChange={onChangeValue1}
       min={0}
      />

{
        Number(is_reserve.r_id) > 0 ? 
<mark>البيانات السابقة : <strong>{is_reserve.paid}</strong>$</mark>
        :''
      }
      <br></br>
<br></br>
{   Number(is_reserve.r_id) > 0 ? 

  <Button variant="warning" type='submit'>
            Update
          </Button>
          :
<Button variant="success" type='submit'>
            Save
          </Button>
}
      
      </Form>
      <button class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose_res}>
            Close
          </Button>
       
        </Modal.Footer>
      </Modal>













<Nav justify className="navbar fixed-bottom bg-dark"  fill >




{ Number(ispaid.paid_id) > 0 ? 
<Nav.Item>
<Button variant="outline-light" onClick={handleShow}>اختيار التشطيب</Button>
</Nav.Item>

:

<Nav.Item>
<Button variant="outline-light" disabled >اختيار التشطيب</Button>
</Nav.Item>

 }



{ Number(ispaid.paid_id) > 0 ? 
<Nav.Item>
<Button variant="outline-light" onClick={handleShow5}> توريد </Button>
</Nav.Item>
:
<Nav.Item>
<Button variant="outline-light" disabled > توريد </Button>
</Nav.Item>
 }

 { Number(ispaid.price) > 0 && Number(ispaid.paid_id) === 0 ? 
<Nav.Item>
<Button variant="outline-light" onClick={handleShow4}> بيع  </Button>
</Nav.Item>

: Number(ispaid.paid_id) > 0 ? 
<Nav.Item>
<Button variant="outline-light"  onClick={handleShow4}>   <img
                src="https://tower.future-swift.com/tool_img/edit.png"
                style={{width:'25px',height:'25px'}}
                className="rounded me-2"
                alt=""
              /> البيع  </Button>
</Nav.Item>

:
<Nav.Item>
<Button variant="outline-light" disabled > بيع  </Button>
</Nav.Item>
 }


 
 {Number(ispaid.paid_id) === 0 && Number(is_reserve.r_id) ===0 ? 
<Nav.Item>
<Button variant="outline-light" onClick={handleShow_res}> حجز  </Button>
</Nav.Item>


: Number(is_reserve.r_id) > 0 && Number(ispaid.paid_id) === 0  ? 
<Nav.Item>
<Button variant="outline-light"  onClick={handleShow_res}>   <img
                src="https://tower.future-swift.com/tool_img/edit.png"
                style={{width:'25px',height:'25px'}}
                className="rounded me-2"
                alt=""
              /> حجز  </Button>
</Nav.Item>


:''
 }

</Nav>

                    
                  
                    

  </div>
)
  }
export default Getapartment;









