import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';
import Table from 'react-bootstrap/Table';

import ReactToPrint from 'react-to-print';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import {useAuthUser} from 'react-auth-kit'

import DatalistInput from 'react-datalist-input';
import Spinner from 'react-bootstrap/Spinner';
const Reserved = () => {

  const auth = useAuthUser();  




const [out, setOutput] = useState({
  name: '',
  any: '0',
  all: '0',
  apart: '',
  building: '',
});





useEffect( () => {

    building();
  All_clients_acc(0);
}, []); 


const [loading, setLoading] = useState(false);

    
const [spin, setSpin] = useState(false);






const [apartments, setApartments] = useState([]);
const apart = async (b_id) => {
    setSpin(true)
    setApartments([])

    try {
  
  
      
      axios.get('https://tower.future-swift.com/api/apart.php?b_id='+b_id)
      .then(res => {
        
        setApartments(res.data.productlist.productdata);
        setSpin(false)
      })
    } catch (error) { throw error;}    
   
}

const [project, setProject] = useState([]);
const building = async () => {
 
    setLoading(true);
    try {
  
  
      
      axios.get('https://tower.future-swift.com/api/buildings.php')
      .then(res => {
        
        if(res.data.success === true ){
          setProject(res.data.productlist.productdata);
          }
        setLoading(false);
      })
    } catch (error) { throw error;}    
   
}


const handdle_change = (id) => {
  setOutput({
    ...out,
    ['building']:id
  }); 

 apart(id);
 clients_acc(id);
}

const handdle_change1 = (id) => {
    setOutput({
      ...out,
      ['apart']:id
    }); 
  
  clients_acc1(id);
  
   //apart(id);
  }

  const [type, setType] = useState('');

const [acc, setAcc] = useState([]);

const clients_acc = async (id) => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/reserved.php?b_id=`+id+'&any='+out.any+'&all='+out.all+'&apart='+out.apart+'&username='+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
          setType(res.data.type)

          setAcc([]);
          setAcc(res.data.productlist.productdata);

        }else{
          alert(res.data.msg)
        }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}




const clients_acc1 = async (id) => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/reserved.php?b_id=`+out.building+'&any='+out.any+'&all='+out.all+'&apart='+id+'&username='+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
          setType(res.data.type)

          setAcc([]);
          setAcc(res.data.productlist.productdata);

        }else{
          alert(res.data.msg)
        }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}


const All_clients_acc = async (id) => {
  setLoading(true);

try {
 
  axios.get(`https://tower.future-swift.com/api/reserved.php?any=`+id+'&username='+auth().username+'&key='+auth().key)
  .then(res => {
      if(res.data.success===true){
        setType(res.data.type)

        setAcc([]);
        setAcc(res.data.productlist.productdata);

      }else{
        alert(res.data.msg)
      }
    
  setLoading(false);

  })
} catch (error) { throw error;}    

}



const All_clients_acc1 = async (id) => {
    setLoading(true);
  
  try {
   
    axios.get(`https://tower.future-swift.com/api/reserved.php?all=`+id+'&b_id='+out.building+'any='+1+'&username='+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
  
          setType(res.data.type)
          setAcc([]);
          setAcc(res.data.productlist.productdata);
  
        }else{
          alert(res.data.msg)
        }
      
    setLoading(false);
  
    })
  } catch (error) { throw error;}    
  
  }

const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  if(e.target.name==='all' && e.target.value==='0' ){
  All_clients_acc1(0);
  }
} 


const onChangeValue1 = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
  
    if(e.target.name==='any' && e.target.value==='0' ){
    All_clients_acc(0);
    }
  } 


const [enddate, setendDate] = useState(new Date());
const [startDate, setStartDate] = useState(new Date()); 

const filt = () =>{
  //  setClientinf([]);
    
  //  clientft(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 



var nf = new Intl.NumberFormat();
const tableRef = useRef(null);


var i =0;
return (
  
  <div >
<img className='center1' style={{position:'fixed' , zIndex:-1}} src='../.././logo2.png' />
     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 

        <div>



<br></br>
      <br></br>
      <br></br>
      <div className="list"> 

<div dir='rtl' style={{margin:'20px',width:'90% ', maxWidth:'1000px'}} class="form-container">
          
<img  style={{position:'absolute',width:'200px' , zIndex:0}} src='.././logo2.png' />
        <div dir='ltr' class="form-group">
          <label>   عرض  </label>
            
<div class="container">
{
out.any === '0' ?

<form>

          <label>

          
			<input name='any' type="radio" value='0'  defaultChecked onChange={onChangeValue1} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='any' type="radio" value='1'  onChange={onChangeValue1}/>
			<span>برج محدد</span>
		   </label>
       </form>


:

<form>

          <label>

          
			<input name='any' type="radio" value='0'   onChange={onChangeValue1} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='any' type="radio" value='1' defaultChecked onChange={onChangeValue1}/>
			<span>برج محدد</span>
		   </label>
       </form>
}
</div>   

        </div>
        {
out.any > 0 ? 
        <div dir='rtl' class="form-group">
        <hr></hr>
          <label for="textarea">ابحث واختر برج...</label>

<DatalistInput

style={{color:'#000',}}
placeholder="البرج"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  project.map(option => (

  { id: option.id, value:option.name }
  
  ))

}
/>

</div>


:''
  
}

<div dir='ltr' class="container">
{ out.all ==='0' && out.any > 0 ? 

<form>

          <label>

          
			<input name='all' type="radio" value='0'  defaultChecked onChange={onChangeValue} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='all' type="radio" value='1'  onChange={onChangeValue}/>
			<span>شقة محددة</span>
		   </label>
       </form>
      
         
  
     
:out.all >0 && out.any > 0 ? 
<form>

          <label>

          
			<input name='all' type="radio" value='0'   onChange={onChangeValue} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='all' type="radio" value='1' defaultChecked onChange={onChangeValue}/>
			<span>شقة محددة</span>
		   </label>
       </form>


:''
    
}
</div>


{out.all > 0 && out.any > 0 ? 
    <div dir='rtl' class="form-group">
<label for="textarea">ابحث واختر شقة...</label>
{
 spin ?  <Spinner animation="border" />
 :
<DatalistInput

style={{color:'#000',}}
placeholder="الشقة"
autoSave='off'
onSelect={(item) => handdle_change1(item.id)}
items={  apartments.map(option => (

  { id: option.apart_id, value:`${option.a_num}` }
  
  ))

}
/>

}
</div>
:''
}




<hr></hr>
<div dir='rtl' class="form-group">    
       
<label for="textarea">فلترة حسب التاريخ :</label>

<div style={{display:'flex'}} >

  



<div style={{margin:20}}>


    <label htmlFor="_date">من:</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   </div> 
   
    <div style={{margin:20}}>
    <label htmlFor="_date">الى:</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
</div>   
 

         
          </div>
<button class="form-submit-btn" type="submit">بحث</button>
        </div> 
         
    </div>



     
  </div>    


        <div dir='rtl'>

 

        
<br></br>
<ReactToPrint
    trigger={() => <button style={{background:'transparent',border:'none',margin:'30px'}} >    <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' /> </button>}
        content={() => tableRef.current}
      />
<div className='container2'>
<Table dir='rtl' striped responsive  hover variant="light" ref={tableRef}>
<caption style={{textAlign:'center',captionSide:'top' }}><h1> الحجوزات </h1></caption>
  
    <thead>
  
    <tr>





    <th scope="col"> رقم العملية </th>
    <th scope="col"> رقم العميل </th>

    {
      type==='1'?
      
      <th scope="col">اسم العميل</th>

      :''
    }

    <th scope="col">برج</th>
    <th scope="col">شقة</th>
    {
      type==='1'?
      
  
      <th scope="col">السعر</th>
      :''
    }
    {
      type==='1'?
      <th scope="col"> المدفوع</th>

      :''
    }
    {
      type==='1'?
      
      <th scope="col">المتبقي</th>

      :''
    }



    <th scope="col">المستخدم</th>
    <th scope="col">تاريخ</th>
    <th scope="col">تفصيل</th>
       
   
   
  
        </tr>
        </thead>
  <tbody>

  {acc.map((item,index)=>(
   
   

        <tr>
        <td>{item.r_id}</td>
        <td>{item.buyer_id}</td>
        {
      type==='1'?
      
      <td>{item.b_name}</td>

      :''
    }
    
        <td>{item.building}</td>
        <td>{Number(item.floor)*100+Number(item.num)}</td>
        {
      type==='1'?
      
      <td>{nf.format(item.price)}</td>

      :''
    }

    {
      type==='1'?
      
      <td>{nf.format(item.paid)}</td>

      :''
    }
    
    {
      type==='1'?
      
      <td> {nf.format(Number(item.price) - Number(item.paid)) } </td>
      :''
    }
    
      
     
        <td>{item.u_name}</td>
        <td>{item.created_at}</td>
        <td> حجز </td> 

<td className='hide' >{i +=1 } </td>
     
       </tr>
     
  

 

 
))}

</tbody>

{
      type==='1'?
      <tfoot >
  

  <tr>
  <th  scope="row">: عدد الحجوزات </th>
    <td colSpan={10}><b>({i})</b> </td>
    
    </tr>
  
  
  
  
  
    </tfoot>
      :''
    }

</Table>

</div>


<br></br>     


</div>

</div>

)}




  </div>
)
}


export default Reserved;









