import React,{useState,useEffect} from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useSignOut } from "react-auth-kit";
import {useAuthUser} from 'react-auth-kit'
import Popup from './Popup';

const Profile = () => {




 const auth = useAuthUser();  
 const logout = useSignOut();
  useEffect( () => {
    

    user();
    
  }, []); 
  
  const [userInfo1, setuserInfo1] = useState({});
  const [userInfo, setuserInfo] = useState([]);
   
  
  const user = async () => {
    setLoading(true);
    try {
      const username =  auth().username;
    const key = auth().key;
      axios.get(`https://tower.future-swift.com/api/userinfo.php?key=`+key+`&username=`+username)
      .then(res => {
        setuserInfo([]);
        console.log(res.data.userinfo.userdata);
        setuserInfo(res.data.userinfo.userdata[0]);
        setuserInfo1(res.data.userinfo.userdata[0]);
      })
     
    } catch (error) { throw error;}    
    finally{
      setTimeout(() => {
        setLoading(false);
      }, 400);
    }  
  }


    const onChangeValue = (e) => {
        setuserInfo({
        ...userInfo,
        [e.target.name]:e.target.value
      });
      console.log(e.target.value)
    } 
    // Inserting a new user into the Database.
   
  
  
    const [file,setFile] = useState("");
  
    const setimgfile = (e)=>{
      setFile(e.target.files[0])
  }
  
  const [loading, setLoading] = useState(false);

  const addUserData = async(e)=>{
    setLoading(true);

      e.preventDefault();
      const username =  auth().username;
      const key = auth().key;
      
      var formData = new FormData();
      formData.append("photo",file); 
      formData.append("username",username); 
      formData.append("key",key); 
      
      try {
      
       await axios.post("https://tower.future-swift.com/api/profile_pic.php",formData)
     
       .then(res => {
        window.location.reload(false);
      })
     
      } catch (ex) {
        console.log(ex);
       
      
      } finally{
        setTimeout(() => {
          setLoading(false);
        }, 400);
      }  
    
  };





  const updateuser = ()=>{


   
     const username1 =  auth().username;
     const key1 = auth().key;
      
      var formData = new FormData();
  
      formData.append("username",username1); 
      formData.append("key",key1); 

      formData.append("user",userInfo.username); 
      formData.append("email",userInfo.email); 
      formData.append("name",userInfo.name); 
      formData.append("pas",userInfo.pass); 
   
      
      try {
      
       axios.post("https://tower.future-swift.com/api/updateuser.php",formData)
       .then(res => {
        window.location.reload(false);
      })
     
      } catch (ex) {
        console.log(ex);
       
      }
  };






  const [isconfirm, setIsconfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

const [v_code, setV_code] = useState(0);
const [pas, setPas] = useState('');


const setdata = (e)=>{
  setV_code(e.target.value);
 
}

const setdata1 = (e)=>{
  setPas(e.target.value);
 
}


const togglePopup = () => {

  setV_code();
  if(isOpen === true){

  }else{
    verfication();
  }
  
  setIsOpen(!isOpen);
 
  }

  
const togglePopup1 = () => {
 
  setIsconfirm(!isconfirm);
 
  }

  
const [v_code1, setV_code1] = useState(0);
const [isOpen1, setIsOpen1] = useState(false);
const setdata2 = (e)=>{
  setV_code1(e.target.value);
}
const togglePopup2 = () => {
 
  if(isOpen1 === true){

  }else{
    verfication();
  }

  setIsOpen1(!isOpen1);
 
  }

  
  const verfication = async()=>{
    setLoading(true);

      
      const username =  auth().username;
      const key = auth().key;
      
      var formData = new FormData();
      
      formData.append("username",username); 
      formData.append("key",key); 
      formData.append("email",userInfo.email); 
      
      
      try {
      
       await axios.post("https://tower.future-swift.com/api/testmail.php",formData)
     
       .then(res => {
       
      })
     
      } catch (ex) {
        console.log(ex);
       
      
      } finally{
        setTimeout(() => {
          setLoading(false);
        }, 400);
      }  
    
  };
  
  

 


 
const alertConfirm = () => {
  if (window.confirm("Are you sure you want confirm ?")) {
    togglePopup();
  }
}; 
  

const active_alert = () => {
  if (window.confirm("Are you sure you want active your email?")) {
    togglePopup2();
  }
}; 

const checkverfication = async(v_code) => {
 
  setLoading(true);

      
  const username =  auth().username;
  const key = auth().key;
  
  var formData = new FormData();
  
  formData.append("username",username); 
  formData.append("key",key); 
  formData.append("code",v_code);
  
  
  try {
  
   await axios.post("https://tower.future-swift.com/api/checkver.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      setIsconfirm(true);
    
      setIsOpen(false);
    }else{

      alert('Invalid code!');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  
}; 



const checkverfication1 = async(v_code) => {
 
  setLoading(true);

      
  const username =  auth().username;
  const key = auth().key;
  
  var formData = new FormData();
  
  formData.append("username",username); 
  formData.append("key",key); 
  formData.append("code",v_code);
  
  
  try {
  
   await axios.post("https://tower.future-swift.com/api/checkver_active.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){

      setIsOpen1(false);
      window.location.reload(false);

    }else{

      alert('Invalid code!');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  
}; 



const changepassword = async(pas) => {

  setLoading(true);

  if(pas.length<=8){
    alert('اخل كلمة سر اكبر من 8 ارقام او احرف');
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }else{

 
 

      
  const username =  auth().username;
  const key = auth().key;
  
  var formData = new FormData();
  
  formData.append("username",username); 
  formData.append("key",key); 
  formData.append("password",pas);




  
  
  try {
  
   await axios.post("https://tower.future-swift.com/api/changepassword.php",formData)
 
   .then(res => {
    if(res.data.Status==='1'){
     
      alert('تم تغيير كلمة السر بنجاح');


      logout();
      localStorage.removeItem('key');
      localStorage.clear();
      window.location.reload(false);
     

    }else{

      alert('فشل تغيير كلمة السر ');
      
    }
    
  })
 
  } catch (ex) {
    console.log(ex);
   
  
  } finally{
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }  
  }
}; 
  
  return (
    <div>  
    

      {loading ? (
        <div className="loader-container">
      	  <div className="spinner"></div>
        </div>
        
      ) : ( <div className="list" style={{marginTop:90,marginBottom:50}}>
    
      <h1> Profile </h1>
            
<form class="form"  onSubmit={updateuser} autoComplete='off'>
    <p class="title" dir='ltr'> Profile </p>
    <p class="message"  dir='rtl'> الملف الشخصي </p>
      <hr></hr>
            
<img  className='center1' style={{position:'absolute' , zIndex:0}} src='.././logo2.png' />
   
        <label for="" class="form-label">Profile Picture:   <br></br>
      <img style={{width:'300px'}} src={'https://tower.future-swift.com/api/profile/'+userInfo.picture} alt="" /></label>
      <br></br>
 
      <label for="" class="form-label">Change Profile Picture:   <br></br> 
  <Form.Control class="form-control form-control-lg" accept="image/*" type="file" size="sm" onChange={setimgfile}/></label> 
  <Button onClick={addUserData} style={{zIndex:1}}  variant="warning">Update</Button>
  <br></br>
  <hr></hr>
  <br></br>
  <label htmlFor="_name">Name:
      <input
        type="text"
        id="_name"
        name="name"
        value={userInfo.name}
        readOnly
      />
     
      </label>
      <br /> <br />
      <label htmlFor="_username">Username:
      <input
        type="text"
        id="_username"
        name="username"
      value={userInfo.username}
      readOnly

      /></label>
      <br /> <br />
    
      <label htmlFor="_name">Email:
  
      <input
        type="email"
        id="_email"
        name="email"
        value={userInfo.email}
        onChange={onChangeValue}
        placeholder="Enter Email"
        autoComplete="off"
        required
      />
      &emsp;
 {userInfo1.email ===null  ? ''
     :
      userInfo.isactive  < 1 ?
      <Button  onClick={active_alert} style={{zIndex:1}} variant="outline-warning"> تفعيل </Button>
        :  <Button  disabled style={{zIndex:1}} variant="outline-success"> تم التفعيل </Button>
     }
</label>
 
      <br /> <br />
  
     

      <label htmlFor="_password">Re-write password:
      <input
        type="password"
        id="_password"
        name="pass"
        value={userInfo.pass}
        onChange={onChangeValue}
        placeholder="Enter Password"
        autoComplete="off"
        required
      /></label>
      <br /> <br />
      
      <Button type="submit" style={{zIndex:1}}  variant="warning" >تحديث</Button>
      <br /> <br />
      <Button   onClick={alertConfirm} style={{zIndex:1}} variant="outline-light"> تغيير كلمة السر</Button>
      </form>
     

    {isOpen && <Popup
      content={<>
        <h4><b>Verfication Code </b></h4>
        <hr></hr>
        <p>للتأكيد<br></br> 
        يرجى ادخال الكود<br></br> 
        الذي تم ارسالة الى ايميلك </p>
        
        
        <hr></hr>
        <form className="_verication" onSubmit={() => checkverfication(v_code)}>
        <label htmlFor="_verication">Verfication Code</label>
        <input onChange={setdata} id='_verication' name='verication' className='confirm' type="text" required/>
        <br></br>
        <Button type="submit" variant='success' >تأكيد</Button>
      </form>
      </>}
      handleClose={togglePopup}
      />}




{isconfirm && <Popup
      content={<>
        <h4><b>Change password</b></h4>
        <hr></hr>
        <p>لتغيير كلمة السر<br></br> 
       يرجى ادخال كلمة السر الجديدة
       <br></br></p>
        
        <hr></hr>
        <form className="_verication" onSubmit={() => changepassword(pas)}>
        <label htmlFor="_password">New Password</label>
      <input
        type="password"
        id="_password"
        name="password"
        onChange={setdata1}
        placeholder="Enter Password"
        autoComplete="off"
        required
      />
      <br /> <br />
        <br></br>
        <Button type="submit" variant='success' >تأكيد</Button>
     
      </form>
      </>}
      handleClose={togglePopup1}
      />}

{isOpen1 && <Popup
      content={<>
        <h4><b>Verfication Code </b></h4>
        <hr></hr>
        <p>للتأكيد <br></br> 
        يرجى ادخال الكود<br></br> 
        الذي تم ارسالة الى ايميلك </p>
        
        <hr></hr>
        <form className="_verication" onSubmit={() => checkverfication1(v_code1)}>
        <label htmlFor="_verication">Verfication Code</label>
        <input onChange={setdata2} id='_verication' name='verication' className='confirm' type="text" required/>
        <br></br>
        <Button type="submit" variant='success' >تأكيد</Button>
      </form>
      </>}
      handleClose={togglePopup2}
      />}



    </div>
 )}


      
    </div>
  );
  };
  
  export default Profile;