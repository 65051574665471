
import { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import {useAuthUser} from 'react-auth-kit'

const Operation = () => {
   
   

  const auth = useAuthUser();  

    useEffect( () => {

   
  
      sub_utl()
      allproduct()
    
      subcat()
        history();
        all_users()
        all_buyers()
    }, []); 
    
    




 
    const cat =[

      {value:"1", label:"الغرف"},
      {value:"2", label:"الحمامات"},
      {value:"3", label:"المطابخ"},
      {value:"4", label:"اخرى"},

    
    ];
    const [emp1, setEmp1] = useState([]);
    
    
    
    

    const subcat = async (cat) => {
      setSpin(true)
    
      try {
       
        axios.get(`https://tower.future-swift.com/api/allsubcat.php`)
        .then(res => {
         
          if(res.data.success === true ){
            setEmp1(res.data.productlist.productdata);
            }
         
          
          setSpin(false)
        })
      } catch (error) { throw error;}    
    
    }
    



    const [buyers, setBuyers] = useState([]);
    const all_buyers = async () => {
      setLoading(true);
      
      try {
       
        axios.get(`https://tower.future-swift.com/api/clients.php?username=`+auth().username+'&key='+auth().key)
        .then(res => {
      
          if(res.data.success===true){
      
            setBuyers(res.data.productlist.productdata);
        
             setLoading(false);
      
          }else{
             
         
        
             setLoading(false);
          }
      
        })
      } catch (error) { throw error;}    
      
      }

    const [users, setUsers] = useState([]);
    const all_users = async () => {
      setLoading(true);
      
      try {
       
        axios.get(`https://tower.future-swift.com/api/allusers.php`)
        .then(res => {
      
          if(res.data.success===true){
      
            setUsers(res.data.productlist.productdata);
        
          // var obj = JSON.parse(res.data.detaillist.detaildata)
      
             //alert(res.data.detaillist.detaildata)
        
             setLoading(false);
      
          }else{
             
            
        
             setLoading(false);
          }
      
        })
      } catch (error) { throw error;}    
      
      }


    const [subutl, setSubutl] = useState([]);
    const sub_utl = async () => {
      setLoading(true);
      
      try {
       
        axios.get(`https://tower.future-swift.com/api/get_sub_utl.php`)
        .then(res => {
      
          if(res.data.success===true){
      
            setSubutl(res.data.productlist.productdata);
        
          // var obj = JSON.parse(res.data.detaillist.detaildata)
      
             //alert(res.data.detaillist.detaildata)
        
             setLoading(false);
      
          }else{
             
              alert(res.data.msg)
        
             setLoading(false);
          }
      
        })
      } catch (error) { throw error;}    
      
      }


    const [loading, setLoading] = useState(false);





    const [main, setMain] = useState([]);
    
    
    const history = async () => {
    setLoading(true);
    
    try {
     
      axios.get(`https://tower.future-swift.com/api/history.php?username=`+auth().username+'&key='+auth().key)
      .then(res => {
    
        if(res.data.success===true){
    
            setMain(res.data.productlist.productdata);
      
        // var obj = JSON.parse(res.data.detaillist.detaildata)
    
           //alert(res.data.detaillist.detaildata)
      
           setLoading(false);
    
        }else{
           
            alert(res.data.msg)
      
           setLoading(false);
        }
    
      })
    } catch (error) { throw error;}    
    
    }
    
    
    const [spin, setSpin] = useState(false);
    
    const [data, setData] = useState([]);

    const [data1, setData1] = useState([]);
    const history_det = async (id) => {
      setData([]);
      setSpin(true)
      
      try {
       
        axios.get(`https://tower.future-swift.com/api/history_detail.php?h_id=`+id)
        .then(res => {
      
          if(res.data.success===true){
      
            setData([]);
             
          var obj = JSON.parse(res.data.detail)
          setData(obj);
         
          setSpin(false);

          setData1(res.data.productlist.productdata);
      
          }else{
            setData1([]);
            setData([]);
            setSpin(false);
              alert(res.data.msg)
        
         
          }
      
        })
      } catch (error) { throw error;}    
      
      }
    

      function CustomToggle({ children, eventKey }) {

        const decoratedOnClick = useAccordionButton(eventKey, () =>
        
        history_det(eventKey)
        );
      
        return (
          <Button
           variant="outline-dark"
         style={{width:'100%'}}
            onClick={decoratedOnClick}
          >
            {children}
          </Button>
        );
      }





      const [project, setProject] = useState([]);




      
      const allproduct = async () => {
        
      
        try {
         
      
          setLoading(true);
       
          
          axios.get('https://tower.future-swift.com/api/design.php')
          .then(res => {
            if(res.data.success === true ){
              setProject(res.data.productlist.productdata);
              }
            setLoading(false);
          })
        } catch (error) { throw error;}    
       
      }
   
      const options =[

        {value:"1", label:"شمال"},
        {value:"2", label:"جنوب"},
        {value:"3", label:"شرق"},
        {value:"4", label:"غرب"},
        {value:"5", label:"شمال شرق"},
        {value:"6", label:"شمال غرب"},
        {value:"7", label:"جنوب شرق"},
        {value:"8", label:"جنوب غرب"},
        {value:"9", label:"شرقي شمال "},
        {value:"10", label:"غربي شمال"},
        {value:"11", label:"شرقي جنوبي"},
        {value:"12", label:"غربي جنوبي"},
      
      ];






      var i =0;
    return (
  
  
        <div dir='ltr' >
         
         {loading ? (
          <div className="loader-container" style={{position:'absolute'}}>
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
              <div>
       
  <Accordion defaultActiveKey="0">
   
   {main.map((item,index)=>(
 
     <Card>
         <Card.Header>
           <CustomToggle eventKey={item.h_id}><strong>{i+=1} - تم {item.title}  
           {item.d_type==='3'  ? item.apart_num1  
            :item.d_type ==='4' ?item.apart_num 
            :item.d_type ==='5' || item.d_type ==='6' || item.d_type ==='11'  ? Number( (item.floor*100))+ Number(item.a_num) 
            :''}</strong>  <small style={{float:'right'}}>{item.username}</small>  <small style={{float:'left'}}> {item.date} </small></CustomToggle>
         </Card.Header>
         <Accordion.Collapse eventKey={item.h_id}>
         <Card.Body style={{width:'100%'}}>
 {
 spin ?  <Spinner animation="border" />
 :
 item.d_type === "1" ?
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>مستخدم ({item.row_id})</h4>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ float:'right' ,paddingRight:'20px'}}>الأسم: <b>{data.name}</b></li>
 <li  style={{ float:'right' ,paddingRight:'20px'}}>اسم المستخدم: <b>{data.username }</b></li>
 <br></br>

 <li style={{paddingRight:'10px'}}>  بريد الكتروني: <b>{data.email}</b></li>
 <li  style={{ float:'right' ,paddingRight:'20px'}}> نوع الحساب <b>{data.type === '1' ? 'اداري' :data.type === '2' ? 'هندسة' : 'مبيعات' }</b></li>
 
 <br></br>
 
 <li  style={{ float:'right' ,paddingRight:'20px'}}> أضافة <b>{data.add > 0 ? "مسموح" : "غير مسموح"}</b></li>
 <li  style={{ float:'right' ,paddingRight:'20px'}}> تعديل <b>{data.edit > 0 ? "مسموح" : "غير مسموح"}</b></li>
 <li  style={{ float:'right' ,paddingRight:'20px'}}> حذف <b>{data.delete > 0 ? "مسموح" : "غير مسموح"}</b></li>
 
 <br></br>
 
 </ul> 
 
 </div>
 
 :item.d_type === "2" ?
 
 
 <div className="container2">

 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>برج </h4>
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ float:'right' ,paddingRight:'20px'}}>الأسم: <b>{data.name}</b></li>
 <li style={{paddingRight:'10px'}}>    &emsp; العنوان: <b>{data.address}</b></li>
 <br></br>
 
 <li  style={{ float:'right' ,paddingRight:'20px'}}>عدد الطوابق: <b>{data.floors}</b></li>
 <li  style={{ paddingRight:'20px'}}> &emsp; شقق / دور: <b>{data.apart}</b></li>
 <br></br>
 
 
 <li  style={{ float:'right' ,paddingRight:'20px'}}> الموقع:<a target='_blank' href={data.location}>
 <img style={{width:'30px'}} src="./map.png" />
 </a></li>
 
 <li  style={{ marginRight:'10px'}}>   &emsp; &emsp;  صورة المخطط: <a  href={'https://tower.future-swift.com/api/b_plan/'+data.b_plan}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>
 <br></br>
 
 </ul> 
 
 </div>
 
 
 
 :item.d_type === '3' ?
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4> مخطط ({item.apart_num1})  </h4>
 لبرج: <strong>({item.b_name})</strong> 
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 { item.o_type ==='1'  ? <div>
 <li  style={{ paddingRight:'20px'}}>مجموعة عناصر: <b>
 

 {project.map(option => (
      
      option.d_id === data.d_id ?
           
       option.name
      
           :''
            
          ))}
 
 
 
 
 </b></li>
 <li  style={{ paddingRight:'20px'}}> الأتجاة : <b>
 
  {options.map(option => (
      
      option.value === data.direction ?
           
       option.label
      
           :''
            
          ))}</b></li>
 

      <li  style={{ marginRight:'10px'}}>   صورة المخطط: <a  href={'https://tower.future-swift.com/api/plan_img/'+data.img}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>

</div>



  :item.o_type ==='2' ? 


<div>
<li  style={{ paddingRight:'20px'}}>مجموعة عناصر: <b>
 

 {project.map(option => (
      
      option.d_id === data.d_id ?
           
       option.name
      
           :''
            
          ))}
 
 
 
 
 </b></li>
 <hr></hr>
تم التعديل الى
 <li  style={{ paddingRight:'20px'}}> الأتجاة : <b>
 
  {options.map(option => (
      
      option.value === data.direction ?
           
       option.label
      
           :''
            
          ))}</b></li>
 

      <li  style={{ marginRight:'10px'}}>   صورة المخطط: <a  href={'https://tower.future-swift.com/api/plan_img/'+data.img}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>
<hr></hr>
البيانات السابقة
<li  style={{ paddingRight:'20px'}}> الأتجاة : <b>
 
 {options.map(option => (
     
     option.value === data.direction_ ?
          
      option.label
     
          :''
           
         ))}</b></li>


     <li  style={{ marginRight:'10px'}}>   صورة المخطط: <a  href={'https://tower.future-swift.com/api/plan_img/'+data.img_}>
<img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
عرض 
</a></li>

</div>


:item.o_type==='3'?

<div>
<li  style={{ paddingRight:'20px'}}>مجموعة عناصر: <b>
 

 {project.map(option => (
      
      option.d_id === data.d_id ?
           
       option.name
      
           :''
            
          ))}
 
 
 
 
 </b></li>
 <li  style={{ paddingRight:'20px'}}> الأتجاة : <b>
 
  {options.map(option => (
      
      option.value === data.direction ?
           
       option.label
      
           :''
            
          ))}</b></li>
 
          <li  style={{ marginRight:'10px'}}>   صورة المخطط: <a  href={'https://tower.future-swift.com/api/plan_img/'+data.img}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>

</div>

  :''
}




 </ul> 
 
 </div>
 
 
 :item.d_type === '4' ?
 
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 
 <h4>تشطيبات مخطط ({item.apart_num})  </h4>
 لبرج: <strong>({item.b_name})</strong> 
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>

 <li style={{paddingRight:'10px'}}>    &emsp; العنصر: <b>{item.sc_name}</b></li>
 <br></br>
 { Number(item.acu_d_id) > 0 ? 

<div>
النوع:
<br></br>

<div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>B</h4>

<strong> ({  subutl.map(sub => (sub.su_id === item.b_su_id ? sub.name  : '' ))}) </strong>



</div>
</div> 
<br /><br />

<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4>C</h4>


<strong> ({  subutl.map(sub => (sub.su_id === item.c_su_id ? sub.name  : '' ))}) </strong>


</div>

<div style={{border:'3px #000 solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"15px 10px" ,textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>E</h4>

 <strong>({  subutl.map(sub => (sub.su_id === item.e_su_id ? sub.name  : '' ))}) </strong>

 <br />
      <br />
      <br />
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
<img src='https://tower.future-swift.com/tool_img/plan_door2.png' style={{width:'30px',height:'30px'}} />
</div> 
</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'left'}}>A</h4>


<strong> ({  subutl.map(sub => (sub.su_id === item.a_su_id ? sub.name : '' ))})</strong>


</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>D</h4>


<strong> ({  subutl.map(sub => (sub.su_id === item.d_su_id ? sub.name  : '' ))})</strong>

</div>
</div> 

<br /><br />
<br /><br />



</div>






</div>
:
<li  style={{ paddingRight:'20px'}}> &emsp;  نوع : <b>{item.su_name}</b></li>
 }
 
 <br></br>
 
 <li  style={{ paddingRight:'20px'}}> &emsp;  ملاحظة: <b>{data.note}</b></li>
 <br></br>
 <li  style={{ marginRight:'10px'}}>   &emsp; &emsp;  صورة : <a  href={'https://tower.future-swift.com/api/utilities/'+data.img}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>

 
 </ul> 
 
 </div>
 
 
 :item.d_type === '5' ?
 
  
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>تحديث سعر الشقة ({Number( (item.floor*100))+ Number(item.a_num)})</h4>
 لبرج: <strong>({item.b_name})</strong> 
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}>السعر : <b>{data.price}</b></li>
 <hr></hr>
 <li  style={{ paddingRight:'20px'}}>السعر القديم : <b>{data.price_old}</b></li>
 <li  style={{ paddingRight:'20px'}}> قام بادخال العملية القديمة: <strong> ({  users.map(user => (user.id === data.us_id ? <strong>{user.username} ({user.is_admin==='1'? 'ADMIN' :user.type === '1' ? "ادارة" :user.type === '2' ? 'هندسة' : 'مبيعات'})</strong>   : '' ))})</strong></li>
 </ul> 
 
 </div>
 
 :item.d_type === '6' ?
 
  
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>بيع شقة ({Number( (item.floor*100))+ Number(item.a_num)})</h4>
 لبرج: <strong>({item.b_name})</strong> 
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}>المشتري: <b>{item.buyer}</b></li>
 <li  style={{ paddingRight:'20px'}}>المبلغ المدفوع: <b>{item.pay}</b>$</li>
 



 {item.o_type === '2' ? 
<div>
<hr></hr>
البيانات السابقة
<li  style={{ paddingRight:'20px'}}>المشتري: <b>{item.buyer}</b></li>
<li  style={{ paddingRight:'20px'}}>رقم العقد: <b>{data.sell_number}</b></li>
 
<li  style={{ paddingRight:'20px'}}>المبلغ السابق: <b>مذكور في اشعار تعديل التسديد السابق</b></li>
 
 <small style={{float:'left'}}>   قام بالأضافة : { users.map(item => (item.id === data.u_id ? item.username  : '' ))}  </small>
</div>

:""
 }
 
 </ul> 
 
 </div>
 
 
 :item.d_type === '7' ?
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>عميل ({item.row_id})</h4>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ float:'right' ,paddingRight:'20px'}}>الأسم: <b>{data.name}</b></li>
 <br></br>
 
 <li  style={{ marginRight:'10px'}}>   &emsp;  صورة البطاقة: <a href={'https://tower.future-swift.com/api/client_id/'+data.id_img}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>
 <br></br>
 
 </ul> 
 
 </div>
 
 
 :item.d_type === '8' ?
 
 
  
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>تسديد عميل لشقة ({Number( (item.floor*100))+ Number(item.a_num)})</h4>
 لبرج: <strong>({item.b_name})</strong> 
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}>المشتري: <b>{item.buyer}</b></li>
 <li  style={{ paddingRight:'20px'}}>المبلغ المدفوع: <b>{item.pay}</b>$</li>
 



 {item.o_type === '2' ? 
<div>
<hr></hr>
البيانات السابقة
<li  style={{ paddingRight:'20px'}}>العميل: <b>({  buyers.map(b => (b.buyer_id === data.b_id ? b.name  : '' ))})</b></li>
 <li  style={{ paddingRight:'20px'}}>المبلغ المدفوع: <b>{data.pay}</b>$</li>
 
 <small style={{float:'left'}}>   قام بالأضافة : { users.map(item => (item.id === data.u_id ? item.username  : '' ))}  </small>
</div>

:""

}

 </ul> 
 
 </div>
 
 :item.d_type === '9' ?
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>مجموعة للعناصر</h4>
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}>أسم المجموعة: <b>{data.name}</b></li>
 <hr></hr>
 
 {item.o_type === '2' ?
<div>
البيانات السابقة :
  <li  style={{ paddingRight:'20px'}}>  الأسم السابق : <strong> {data.o_name} </strong></li>

 <small style={{float:'left'}}> [ {data.date} ]   قام بالأضافة : { users.map(item => (item.id === data.u_id ? item.username  : '' ))}  </small>
 </div>
 :''
 
 }
 
 
 
 
 </ul> 
 
 </div>
 
 :item.d_type === '10' ?
 
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4> العناصر المرفقة</h4>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}> لمجموعة: <b>{item.d_name}</b></li>
 <hr></hr>
 <li  style={{ paddingRight:'20px'}}> نوع العنصر : <b>{item.C_name}</b></li>
 <li  style={{ paddingRight:'20px'}}> العنصر المرفق: <b>{item.SC_name}</b></li>
<hr></hr>
 
 {item.o_type === '2' ?
<div>
البيانات السابقة :
  <li  style={{ paddingRight:'20px'}}> نوع العنصر : <strong> {  cat.map(item => (item.value === data.cat_id ? item.label  : '' ))} </strong></li>
 <li  style={{ paddingRight:'20px'}}> العنصر المرفق: <strong> {  emp1.map(item => (item.value === data.sc_id ? item.label  : '' ))} </strong></li>
 
 <small style={{float:'left'}}>   قام بالأضافة : { users.map(item => (item.id === data.u_id ? item.username  : '' ))}  </small>
 </div>
 :''
 
 }
 
 
 
 
 
 </ul> 
 
 </div>
 
 //only
 :item.d_type === '11' ?
 
 <div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 
 <h4>تشطيبات شقة رقم ({Number( (item.floor*100))+ Number(item.a_num)})  </h4>
 لبرج: <strong>({item.b_name})</strong> 
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>


 
{  data1.map(sub => ( Number(sub.acu_d_id) > 0 ? 

 
<div>


<li style={{paddingRight:'10px'}}>    &emsp; النوع: <b>{sub.c_name}</b></li>
<li style={{paddingRight:'10px'}}>    &emsp; العنصر: <b>{Number(sub.SC_id) > 0 ? sub.sc_name : 'للكل' }</b></li>

تشطيب نوع: <strong> {sub.u_name}</strong> 


<li  style={{ marginRight:'10px'}}>   &emsp; &emsp;  صورة : <a  href={'https://tower.future-swift.com/api/special/'+sub.pic}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>

<div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>B</h4>


<strong>({  subutl.map(sub1 => (sub1.su_id === sub.b_su_id ? sub1.name  : '' ))}) </strong>
<br />
<strong> {sub.b}  </strong>


</div>
</div> 
<br /><br />

<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4>C</h4>


<strong>({  subutl.map(sub1 => (sub1.su_id === sub.c_su_id ? sub1.name  : '' ))}) </strong>
<br />
<strong> {sub.c}  </strong>

</div>

<div style={{border:'3px #000 solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"15px 10px" ,textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>E</h4>

<strong>({  subutl.map(sub1 => (sub1.su_id === sub.e_su_id ? sub1.name  : '' ))}) </strong>
<br />
<strong> {sub.e}  </strong>

      <br />
      <br />
      <br />
      <div style={{display:'flex',justifyContent:'center'}}>
<img src='https://tower.future-swift.com/tool_img/plan_door2.png' style={{width:'30px',height:'30px'}} />
</div> 
</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'left'}}>A</h4>


<strong>({  subutl.map(sub1 => (sub1.su_id === sub.a_su_id ? sub1.name  : '' ))}) </strong>
<br />
<strong> {sub.a}  </strong>

</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px",textAlign:'center'}} >
<h4 style={{textAlign:'center'}}>D</h4>


<strong>({  subutl.map(sub1 => (sub1.su_id === sub.d_su_id ? sub1.name  : '' ))}) </strong>
<br />
<strong> {sub.d}  </strong>
</div>
</div> 

<br /><br />
<br /><br />



</div>





<li  style={{ paddingRight:'20px'}}> &emsp;  فارق سعر : <b>{Number(sub.price)}</b> $</li>
<li  style={{ paddingRight:'20px'}}>   ملاحظة : <b>{sub.detail}</b></li>

<hr></hr>
</div>
:
<div>
<li style={{paddingRight:'10px'}}>    النوع: <b>{sub.c_name}</b></li>
<li style={{paddingRight:'10px'}}>   العنصر: <b>{Number(sub.SC_id) > 0 ? sub.sc_name : 'للكل' }</b></li>
&emsp;
تشطيب نوع: <strong> {sub.u_name}</strong> 
<li  style={{ paddingRight:'20px'}}>  الى : <b>{Number(sub.SU_id) > 0 ? sub.su_name : 'اخرى'}</b></li>

<li  style={{ paddingRight:'20px'}}>   فارق سعر : <b>{Number(sub.price)}</b> $</li>
<li  style={{ paddingRight:'20px'}}>   ملاحظة : <b>{sub.detail}</b></li>

<li  style={{ marginRight:'10px'}}>   &emsp;  صورة : <a  href={'https://tower.future-swift.com/api/special/'+sub.pic}>
 <img style={{width:'30px'}} src="https://tower.future-swift.com/tool_img/photo.png" />
 عرض 
 </a></li>

<hr></hr>
</div>
))}
 
 <br></br>
 
 

 
 </ul> 
 
 </div>
 


:

<div className="container2">
 <small style={{float:'left'}}> [ {item.date} ]  المستخدم: {item.username}  </small>
 <h4>حجز شقة ({Number( (item.floor*100))+ Number(item.a_num)})</h4>
 لبرج: <strong>({item.b_name})</strong> 
 <hr></hr>
 <ul style={{ margin: 0,padding: 0 ,overflow: 'hidden',listStyleType:'none' }}>
 <li  style={{ paddingRight:'20px'}}>الحاجز: <b>{item.buyer}</b></li>
 <li  style={{ paddingRight:'20px'}}>المبلغ المدفوع: <b>{item.pay}</b>$</li>
 


{item.o_type === '2' ? 
<div>
<hr></hr>
البيانات السابقة
<li  style={{ paddingRight:'20px'}}>الحاجز: <b>({  buyers.map(b => (b.buyer_id === data.b_id ? b.name  : '' ))})</b></li>
 <li  style={{ paddingRight:'20px'}}>المبلغ السابق: <b>مذكور في اشعار تعديل التسديد السابق</b></li>
 
 <small style={{float:'left'}}>   قام بالأضافة : { users.map(item => (item.id === data.u_id ? item.username  : '' ))}  </small>
</div>

:""

}

 </ul> 
 
 </div>

 }
        </Card.Body>
          
         </Accordion.Collapse>
       </Card>
         ))}
      
     
     </Accordion>
       

    </div>
    )}



       </div>
  
)
  }

export default Operation;












