import React, {useEffect,useState,useRef} from 'react'
import axios from 'axios';
import Table from 'react-bootstrap/Table';

import { format } from 'date-fns';
import ReactToPrint from 'react-to-print';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import {useAuthUser} from 'react-auth-kit'

import DatalistInput from 'react-datalist-input';

const Clients_purchases = () => {

  const auth = useAuthUser();  




const [out, setOutput] = useState({
  name: '',
  any: '0',
  client: '',
});





useEffect( () => {

  clients();
  All_clients_acc(0);
}, []); 


const [loading, setLoading] = useState(false);




const [type, setType] = useState('');

const [buyers, setBuyers] = useState([]);


const clients = async () => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/clients.php?username=`+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){
    setBuyers(res.data.productlist.productdata);
    setType(res.data.type)
  }else{
    alert(res.data.msg)
  }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}


const handdle_change = (id) => {
  setOutput({
    ...out,
    ['client']:id
  }); 

  clients_acc(id);

}
  

const [acc, setAcc] = useState([]);

const clients_acc = async (id) => {
    setLoading(true);

  try {
   
    axios.get(`https://tower.future-swift.com/api/clients_purchase.php?buyer_id=`+id+'&any='+out.any+'&username='+auth().username+'&key='+auth().key)
    .then(res => {
        if(res.data.success===true){

          setType(res.data.type)
          setAcc([]);
          setAcc(res.data.productlist.productdata);

        }else{
          alert(res.data.msg)
        }
      
    setLoading(false);

    })
  } catch (error) { throw error;}    

}


const All_clients_acc = async (id) => {
  setLoading(true);

try {
 
  axios.get(`https://tower.future-swift.com/api/clients_purchase.php?any=`+id+'&username='+auth().username+'&key='+auth().key)
  .then(res => {
      if(res.data.success===true){

        setType(res.data.type)
        setAcc([]);
        setAcc(res.data.productlist.productdata);

      }else{
        alert(res.data.msg)
      }
    
  setLoading(false);

  })
} catch (error) { throw error;}    

}

const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  if(e.target.name==='any' && e.target.value==='0' ){
  All_clients_acc(0);
  }
} 



const [enddate, setendDate] = useState(new Date());
const [startDate, setStartDate] = useState(new Date()); 



const clients_acc_filter = async (date,date1) => {
  setLoading(true);

try {
 
  axios.get(`https://tower.future-swift.com/api/clients_purchase_filter.php?buyer_id=`+out.client+'&any='+out.any+'&date='+date+'&date1='+date1+'&username='+auth().username+'&key='+auth().key)
  .then(res => {
      if(res.data.success===true){

        setType(res.data.type)
        setAcc([]);
        setAcc(res.data.productlist.productdata);
   
      }else{
        alert(res.data.msg)
      }
  setLoading(false);

  })
} catch (error) { throw error;}    

}




const filt = () =>{

    
  clients_acc_filter(format(startDate, 'yyyy-MM-dd'),format(enddate, 'yyyy-MM-dd'));
    
  } 





var nf = new Intl.NumberFormat();
const tableRef = useRef(null);


var price =0;
var paid =0;
return (
  
  <div >
<img className='center1' style={{position:'fixed' , zIndex:-1}} src='../.././logo2.png' />
     {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 

        <div>



<br></br>
      <br></br>
      <br></br>
      <div className="list"> 

<div dir='rtl' style={{margin:'20px',width:'90% ', maxWidth:'1000px'}} class="form-container">
          
<img  style={{position:'absolute',width:'200px' , zIndex:0}} src='.././logo2.png' />
        <div dir='ltr' class="form-group">
          <label>   عرض  </label>
            
<div class="container">
{
out.any === '0' ?

<form>

          <label>

          
			<input name='any' type="radio" value='0'  defaultChecked onChange={onChangeValue} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='any' type="radio" value='1'  onChange={onChangeValue}/>
			<span>عميل محدد</span>
		   </label>
       </form>


:

<form>

          <label>

          
			<input name='any' type="radio" value='0'   onChange={onChangeValue} />
			<span>الكل</span>
		   </label>
           
       <label>
			<input  name='any' type="radio" value='1' defaultChecked onChange={onChangeValue}/>
			<span>عميل محدد</span>
		   </label>
       </form>
}
</div>   

        </div>
        {
out.any > 0 ? 
        <div dir='rtl' class="form-group">
        <hr></hr>
          <label for="textarea">ابحث واختر العميل...</label>

<DatalistInput

style={{color:'#000',}}
placeholder="العملاء"
autoSave='off'
onSelect={(item) => handdle_change(item.id)}
items={  buyers.map(option => (
  type === '1'?
  { id: option.buyer_id, value:option.name }
  :  { id: option.buyer_id, value:option.buyer_id }
  
  
  ))

}
/>


         
        
          </div>
:''
  
}

<hr></hr>
<div dir='rtl' class="form-group">    
       
<label for="textarea">فلترة حسب التاريخ :</label>

<div style={{display:'flex'}} >

  



<div style={{margin:20}}>


    <label htmlFor="_date">من:</label>
    <DatePicker selected={startDate} onChange={(date) =>   
setStartDate(date)}
dateFormat="yyyy-MM-dd" /> 
   </div> 
   
    <div style={{margin:20}}>
    <label htmlFor="_date">الى:</label>
    <DatePicker selected={enddate} onChange={(date) =>   
setendDate(date)}
dateFormat="yyyy-MM-dd" /> 
</div>   
 

         
          </div>
<button class="form-submit-btn" onClick={ filt }>بحث</button>
        </div> 
         
    </div>



     
  </div>    


        <div dir='rtl'>

 

        
<br></br>
<ReactToPrint
      trigger={() => <button style={{background:'transparent',border:'none',margin:'30px'}} >    <img style={{width:'30px'}} src='https://tower.future-swift.com/tool_img/printer.png' /> </button>}
        content={() => tableRef.current}
      />
<div className='container2'>
<Table dir='rtl' striped responsive  hover variant="light" ref={tableRef}>
<caption style={{textAlign:'center',captionSide:'top' }}><h1> المبيعات </h1></caption>
  
    <thead>
  
    <tr>





    <th scope="col"> رقم العملية </th>
    <th scope="col"> رقم العميل </th>

   {type === '1'?
   <th scope="col">اسم العميل</th>
  : ''
  } 

    <th scope="col">برج</th>
    <th scope="col">شقة</th>
    {type === '1'?
    <th scope="col">السعر</th>
  : ''
  } 
  {type === '1'?
  <th scope="col"> المدفوع</th>
  : ''
  } 
  {type === '1'?
  <th scope="col">المتبقي</th>
  : ''
  } 


    <th scope="col">المستخدم</th>
    <th scope="col">تاريخ</th>
    <th scope="col">تفصيل</th>
       
    <th scope="col">ملاحظة</th>
   
  
        </tr>
        </thead>
  <tbody>

  {acc.map((item,index)=>(
   
   

        <tr>
        <td>{item.paid_id}</td>
        <td>{item.buyer_id}</td>
        {type === '1'?
        <td>{item.b_name}</td>
  : ''
  } 
    
        <td>{item.building}</td>
        <td>{Number(item.floor)*100+Number(item.num)}</td>
        {type === '1'?
        <td>{nf.format(item.price)}</td>
  : ''
  } 
    
  {type === '1'?
  <td>{nf.format(item.paid)}</td>
  : ''
  } 
  {type === '1'?
  <td> {nf.format(Number(item.price) - Number(item.paid)) } </td>
  : ''
  } 
       
        <td>{item.u_name}</td>
        <td>{item.modify}</td>
        <td> شراء </td> 
        <td>{item.note}</td>
<td className='hide' >{paid += Number(item.paid) } </td>
<td className='hide' >{price += Number(item.price) } </td>
       </tr>
     
  

 

 
))}

</tbody>
 
 {type === '1'?
<tfoot >
  

  <tr>
  <th  scope="row">المبلغ المستحق </th>
    <td colSpan={10}><b>{nf.format(price)}</b> $</td>
    
    </tr>
  
 
  
  
  
    </tfoot>
  
  : ''
  } 
 
</Table>

</div>


<br></br>     


</div>

</div>

)}





  </div>
)
}


export default Clients_purchases;









