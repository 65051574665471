import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import {  useParams } from "react-router-dom";
import Header_in from '../Header_in';
import DatalistInput from 'react-datalist-input';
import {useAuthUser} from 'react-auth-kit';
import { ca } from 'date-fns/locale';
const Getsubcat = () => {
 
  const auth = useAuthUser();  

  let params = useParams();
  
  useEffect( () => {

   
    subutl(params.utl_id);

    categories();
 


}, []); 

const [loading, setLoading] = useState(false);


const [cat, setCat] = useState([]);

const categories = async () => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get(`https://tower.future-swift.com/api/categories.php`)
    .then(res => {
      
      setCat(res.data.productlist.productdata);
      setLoading(false);

      subcat(params.d_id);
      
    })
  } catch (error) { throw error;}    
 
}



const [sub, setSub] = useState([]);

const subcat = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://tower.future-swift.com/api/apart_cat_utl.php?d_id='+id+'&utl_id='+params.utl_id+'&ad_id='+params.ad_id)
    .then(res => {
      
      setSub(res.data.productlist.productdata);
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}



const [out, setOutput] = useState({
  su_id: '',

  ad_id:params.ad_id,
note:"",


a_su_id: '',
b_su_id: '',
c_su_id: '',
d_su_id: '',
e_su_id: '',
});


const onChangeValue = (e) => {
  setOutput({
    ...out,
    [e.target.name]:e.target.value
  });

  
} 


  
const onChangeValue1 = (id) => {
  setOutput({
    ...out,
    ['su_id']:id
  }); 
}


//
const onChangeValue_a = (id) => {
  setOutput({
    ...out,
    ['a_su_id']:id
  }); 
}



const onChangeValue_b = (id) => {
  setOutput({
    ...out,
    ['b_su_id']:id
  }); 
}


const onChangeValue_c = (id) => {
  setOutput({
    ...out,
    ['c_su_id']:id
  }); 
}


const onChangeValue_d = (id) => {
  setOutput({
    ...out,
    ['d_su_id']:id
  }); 
}



const onChangeValue_e = (id) => {
  setOutput({
    ...out,
    ['e_su_id']:id
  }); 
}


const [sub_utl, setSub_utl] = useState([]);

const subutl = async (utl) => {
  

  try {
   
    axios.get(`https://tower.future-swift.com/api/subutl.php?utl=`+utl)
    .then(res => {
     
    
      setSub_utl(res.data.productlist.productdata);
      
     
    })
  } catch (error) { throw error;}    

}



const [file,setFile] = useState("");
  
const setimgfile = (e)=>{
  setFile(e.target.files[0])
}




const add = async(dd_id,cat_id) => {
  setLoading(true);
   
  try {
 

    var formData = new FormData();
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("ad_id",params.ad_id); 
    formData.append("utl_id",params.utl_id); 
    formData.append("su_id",out.su_id);
    formData.append("photo",file); 
    formData.append("note",out.note);
    formData.append("dd_id",dd_id);
    
    formData.append("cat_id",cat_id);

    formData.append("a_su_id",out.a_su_id);
    formData.append("b_su_id",out.b_su_id);
    formData.append("c_su_id",out.c_su_id);
    formData.append("d_su_id",out.d_su_id);
    formData.append("e_su_id",out.e_su_id);
    
    axios.post(`https://tower.future-swift.com/api/add_cat_utl.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};



const update = async(id) => {
  setLoading(true);
   
  try {
 

    var formData = new FormData();
    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    formData.append("acu_id",id); 
    formData.append("utl_id",params.utl_id); 
    formData.append("su_id",out.su_id);
    formData.append("photo",file); 
    formData.append("note",out.note);
  
    
 
    formData.append("a_su_id",out.a_su_id);
    formData.append("b_su_id",out.b_su_id);
    formData.append("c_su_id",out.c_su_id);
    formData.append("d_su_id",out.d_su_id);
    formData.append("e_su_id",out.e_su_id);
    
    axios.post(`https://tower.future-swift.com/api/update_cat_utl.php`, formData)
    .then(res => {
          setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
     })
  } catch (error) { throw error;}    

};



const update_img = async(e)=>{
  setLoading(true);

    e.preventDefault();

    
    var formData = new FormData();
    formData.append("photo",file); 
 

    
    try {
    
     await axios.post("https://tower.future-swift.com/api/change_.php",formData)
   
     .then(res => {
    

    
  
      setLoading(false);

    })
   
    } catch (ex) {
      console.log(ex);
     
    
    } 
  
};





var total=0;
return (
  
  <div >
 <br></br>
 <br></br>
 <br></br>
 <br></br>


    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
          </div>
      ) : ( 


        <div  style={{ width:'100%'}} dir="RTL"  >

        <h1> تشطيبات عناصر مخطط رقم ({params.num})</h1>
  
  <h2>({params.name})</h2>
 
  <br></br>
  <img className='utilities' src={"https://tower.future-swift.com/api/utl_icon/"+params.img}/>
 
     <ul >   
{cat.map((cat,index)=>(
  
  <div>

  <td className='hide' >{sub.map((item,index)=>(item.cat_id===cat.value ?
    <td className='hide' >{total+=Number(item.count)}</td>
    :""
    
    ))}</td>
   
        <li className='categories'>{cat.label} ({total}) </li>
        <ol>
        <br></br>
        <hr></hr>
        
        {sub.map((item,index)=>(
            item.cat_id === cat.value ? 
          
          
          <div>
          <li >
       
          {
              item.acu_id > 0  ?
         <form style={{width:'100% ', maxWidth:'1000px'}} class="form-container">     
     
         <div dir='' class="form-group">


<p style={{fontSize:'20px'}} class="title"> {item.name2} ({item.count}) مخطط رقم ({params.num}) </p>
<p   class="message">  تعديل تشطيب عنصر </p>
 
    <hr></hr>

<img className='utilities' src={"https://tower.future-swift.com/api/utilities/"+item.img}/>
<div dir='' class="form-group">
<label for="" > صورة:
  <input   type="file"  accept="image/*" id="formFileLg" onChange={setimgfile}/>
  
  <Button variant="warning"  onClick={update_img}  >Update</Button>

</label>
</div>
  <hr></hr>
         

  
 
  <div dir='rtl' class="form-group">
       

      
{
 (cat.value ==='2' || cat.value ==='3'  ) && params.utl_id==='1'?
 <div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>B</h4>

<DatalistInput
dir='ltr'
label=": B النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_b(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

<br></br> 
<div> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.b}</small> </mark></div>


</div>
</div> 
<br /><br />
<br /><br />
<br /><br />
<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4>C</h4>

<DatalistInput
dir='ltr'
label=": C النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_c(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<br></br> 
<div> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.c}</small> </mark></div>

</div>

<div style={{border:'3px #fff solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>E</h4>

<DatalistInput
dir='ltr'
label=": E النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_e(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


<div style={{margin:12}}> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.e}</small> </mark></div>


<div style={{display:'flex',justifyContent:'center'}}>
<img src='../../../../../../.././plan_door.png' style={{width:'30px',height:'30px'}} />
</div> 

</div>





</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4 style={{textAlign:'left'}}>A</h4>

<DatalistInput
dir='ltr'
label=": A النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_a(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

<br></br> 
<div> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.a}</small> </mark></div>

</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>D</h4>

<DatalistInput
dir='ltr'
label=": D النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_d(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

<br></br> 
<div> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.d}</small> </mark></div>


     
</div>
</div> 

<br /><br />
<br /><br />
<br /><br />


</div>
  :




  <div dir='rtl' class="form-group">
       

       <DatalistInput
dir='ltr'
label=":نوع التشطيب"
style={{color:'#1a1a1a'}}
 placeholder="Search and choose..."
 onSelect={(item) => onChangeValue1(item.id)}
 items={  
   sub_utl.map(option => (

       
   { id: option.value, value:option.label }

   ))

 }
/>
{  
      sub_utl.map(option => (
      option.value===item.su_id ?
      <div> <small>الخيار السابق:</small>  <mark><small>&ensp;{option.label}</small> </mark></div>
:''

      ))}
</div>  

}
<hr></hr>



   </div>      
    
<br></br>
    <label >ملاحظة:
<input className='select-s' name='note' onChange={onChangeValue} placeholder="ملاحظة" />
</label>



<div> <small>الخيار السابق:</small>  <mark><small>&ensp;{item.note}</small> </mark></div>

<br></br>
<Button variant="warning"  onClick={() => update(item.acu_id)}  >Update</Button>
</div>      
<hr></hr>
   </form>  




:   
<form style={{width:'100% ', maxWidth:'1000px'}} onSubmit={() => add(item.dd_id,cat.value)} class="form-container">     
     
     <div dir='' class="form-group">


<p style={{fontSize:'20px'}} class="title"> {item.name2} ({item.count}) مخطط ({params.num}) </p>

<p class="message"> أضافة تشطيب للعناصر </p>
     <hr></hr>
     <label >صورة:   
   
<input  class="form-control form-control-lg" type="file"  accept="image/*" id="formFileLg" onChange={setimgfile}/>
</label>     


<hr></hr>


{
 (cat.value ==='2' || cat.value ==='3'  ) && params.utl_id==='1'?

 <div>

<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>B</h4>

<DatalistInput
dir='ltr'
label=": B النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_b(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


</div>
</div> 
<br /><br />
<br /><br />
<div style={{display:'flex',justifyContent:'center',margin:10}}>


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4>C</h4>

<DatalistInput
dir='ltr'
label=": C النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_c(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>


</div>

<div style={{border:'3px #fff solid ', width:'200px',height:'200px',display:'flex',justifyContent:'center'}}>



<div style={{width:'90%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>E</h4>

<DatalistInput
dir='ltr'
label=": E النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_e(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>
<br /><br />
<div style={{display:'flex',justifyContent:'center'}}>
<img src='../../../../../../.././plan_door.png' style={{width:'30px',height:'30px'}} />
</div> 

</div>


 

</div>  


<div style={{width:'30%',height:'20px', margin:"25px 10px"}} >
<h4 style={{textAlign:'left'}}>A</h4>

<DatalistInput
dir='ltr'
label=": A النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_a(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

</div>

</div>


<div style={{display:'flex',justifyContent:'center',margin:10}}> 
<div style={{width:'30%',height:'20px', margin:"10px 10px"}} >
<h4 style={{textAlign:'center'}}>D</h4>

<DatalistInput
dir='ltr'
label=": D النوع "
style={{color:'#1a1a1a'}}
placeholder="choose..."
onSelect={(item) => onChangeValue_d(item.id)}
items={  
 sub_utl.map(option => (

     
 { id: option.value, value:option.label }

 ))

}
/>

</div>
</div> 

<br /><br />
<br /><br />
<br /><br />


</div>
  :




  <div dir='rtl' class="form-group">
       

       <DatalistInput
dir='ltr'
label=":نوع التشطيب"
style={{color:'#1a1a1a'}}
 placeholder="Search and choose..."
 onSelect={(item) => onChangeValue1(item.id)}
 items={  
   sub_utl.map(option => (

       
   { id: option.value, value:option.label }

   ))

 }
/>
</div>  

}
<hr></hr>
  <label >ملاحظة:    
   <input autoComplete='off' className='select-s' name='note' type='' onChange={onChangeValue} placeholder="ملاحظة" />
   </label>
   <br></br>
      <Button variant="primary" type='submit'>Save</Button>
       
              </div>
              </form>
        }       
     
             
          </li>
          <br></br>
        
          </div> 
         :""
          ))}
          </ol>
     

          <td className='hide' >{total=0}</td>
</div>
   ))}
</ul>



        </div>
      )}


    
  </div>
)
  }
export default Getsubcat;




