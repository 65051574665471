import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Header_in from '../Header_in';
import { Link } from 'react-router-dom';



const Getsections2 = () => {
 

  useEffect( () => {

   
  

   
 
    allproduct(localStorage.getItem("b_id"));
   

}, []); 





const [apartsec, setApartsec] = useState([]);








const [loading, setLoading] = useState(false);

const allproduct = async (b_id) => {
  setLoading(true);

  try {
   

  
 
    
    axios.get('https://tower.future-swift.com/api/apartsec1.php?b_id='+b_id)
    .then(res => {
      
      setApartsec(res.data.productlist.productdata);
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}


return (
  
  <div >


  
 
    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 


        <div className='container2'>
        <div className="list" style={{marginTop:90,marginBottom:50}} >
          <h1>ادارة تشطيبات البرج</h1>
  <div className="container1">


  {apartsec.map((item,index)=>(


    item.ad_id > 0 ?
    <Link to={`/utilities/${item.d_id}/${item.ad_id}/${item.apart_num}`} className="apartdes1">

    <div className="imglink4" href="">
            

                
           
              </div>
              
              <p style={{width:'90px',fontSize:'12px'}} className='title'><b>({item.apart_num})مخطط</b></p> 

              </Link>
 
:
          <Link to={`/building/${localStorage.getItem('b_id')}/apart_sec`} className="error">          
          
         <b>يجب ان تقوم باضافة عناصر لكل مخطط 
          <br></br>
         ! اضغط هنا للبدء في الاضافة </b>
        
          
          </Link>

))}
</div>
 </div>
</div>
      )}
  </div>
  
)
  }

export default Getsections2;









