import React, {useEffect,useState} from 'react'
import axios from 'axios';



const Get_operation = () => {
   
   


    useEffect( () => {

   
  
        history();
    
        
     
       
       
    
    }, []); 
    
    
    const [loading, setLoading] = useState(false);

const [data, setData] = useState([]);


const [main, setMain] = useState([]);


const history = async () => {
    setLoading(true);

    try {
     
      axios.get(`https://tower.future-swift.com/api/history.php`)
      .then(res => {
   
        if(res.data.success===true){

            setMain(res.data.productlist.productdata[0]);
     
            var obj = JSON.parse(res.data.detail)
            setData(obj);
           // alert(obj)
      
           setLoading(false);

        }else{
           
            alert(res.data.msg)
      
           setLoading(false);
        }
 
      })
    } catch (error) { throw error;}    
  
  }
   
    const operation_type =[

        {value:"1", label:"اضافة"},
        {value:"2", label:"تعديل"},
        {value:"3", label:"حذف"},
        {value:"4", label:"تفعيل"},
        {value:"5", label:"الغاء التفعيل"},
    
      
      ];
      
    
      
    const data_type =[
    
        {value:"1", label:"مستخدمين"},
        {value:"2", label:"ابراج"},
        {value:"3", label:"مخطط الشقق"},
        {value:"4", label:"تشطيبات الشقق"},
        {value:"5", label:"سعر الشقق"},
        {value:"6", label:"بيع الشقق"},
        {value:"7", label:"العملاء"},
        {value:"8", label:"تسديدات العملاء"},
        {value:"9", label:"مجموعة عناصر"},
        {value:"10", label:"عناصر"},
        {value:"11", label:"نوع تشطيب"},
      
      ];
    
    












    return (
  
  
        <div dir='rtl' >
         
         {loading ? (
          <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
        
      ) : ( 
              
        <div className='center1'>
        
        <h2>{main.title}</h2>
        <h3>{data.name}</h3>
       

    </div>
    )}
       </div>
  
)
  }

export default Get_operation;

/*

        <li>
        
        <Button 
         onClick={() => setOpen2(open2 === index ? -1 : index)}
         aria-controls="example-collapse-text"
         aria-expanded={open2 === index}
         className="sub_element"
        variant="outline-light">
      {utl.name} &ensp; <Badge bg="warning">0</Badge>
      <span className="visually-hidden">unread messages</span> 
        </Button>
        </li>


  <Collapse in={open2 === index}>
  <div className="e_content1">
<ul>


  {subutl.map((sub1,index)=>(


 utl.utl_id === sub1.utl_id && item.dd_id === sub1.dd_id  ? 
<div>
<li>{sub1.name}</li>
<li>{sub1.note}</li>
<li> <a  href={`https://tower.future-swift.com/api/utilities/${sub1.img}`}> الصورة </a> </li>
</div> 
: ""




 ))}
  </ul>
  </div>
  </Collapse>
*/










