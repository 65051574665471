import React, {useEffect,useState} from 'react'
import axios from 'axios';
import Header_in from '../Header_in';
import {  useParams } from "react-router-dom";
import DatalistInput from 'react-datalist-input';
import 'react-datalist-input/dist/styles.css';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {useAuthUser} from 'react-auth-kit'
import Button from 'react-bootstrap/Button';

const Apart_des = () => {
    let params = useParams();
    const auth = useAuthUser();  


  useEffect( () => {

    
   
    allproduct();

    allproduct1(params.as_id)

 

}, []); 



const [loading, setLoading] = useState(false);


const [design, setDesign] = useState([]);
const allproduct = async () => {
  

    try {
     
  
      setLoading(true);
   
      
      axios.get('https://tower.future-swift.com/api/design.php')
      .then(res => {
        
        setDesign(res.data.productlist.productdata);
        setLoading(false);
      })
    } catch (error) { throw error;}    
   
  }
  


  const [a_des, setA_des] = useState({});

const allproduct1 = async (as_id) => {
  

    try {
     
  
      setLoading(true);
   
      
      axios.get('https://tower.future-swift.com/api/getapart_des.php?as_id='+as_id)
      .then(res => {
        setA_des([]);
        setA_des(res.data.productlist.productdata[0]);
        setLoading(false);
      })
    } catch (error) { throw error;}    
    
  }



const [file,setFile] = useState("");
  
const setimgfile = (e)=>{
  setFile(e.target.files[0])
}



const [d_id, setD_id] = useState({
 
  dir:"",
  cat:"",

});

  
const onChangeValue = (id) => {
  setD_id({
    ...d_id,
    ['dir']:id
  }); 

}



  
const onChangeValue1 = (id) => {
  setD_id({
    ...d_id,
    ['cat']:id
  }); 
 

}




  const add = async(event) => {
   
    setLoading(true);
    try {
      event.preventDefault();
      event.persist();
  
      var formData = new FormData();
  
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
   


      formData.append("as_id",params.as_id); 
      formData.append("d_id",d_id.cat);
      formData.append("dir",d_id.dir);
      formData.append("photo",file); 
      axios.post(`https://tower.future-swift.com/api/add_apart_des.php`, formData)
      .then(res => {
       
        setLoading(false);
        alert(res.data.msg);
        window.location.reload(false);
        
       
       })
    } catch (error) { throw error;}    
    
  };
  
  











  const update = async(event) => {
   
    setLoading(true);
    try {
      event.preventDefault();
      event.persist();
  
      var formData = new FormData();
      formData.append("username",auth().username); 
  formData.append("key",auth().key); 

      formData.append("ad_id",a_des.ad_id); 
      formData.append("d_id",d_id.cat);
      formData.append("dir",d_id.dir);
      formData.append("photo",file); 
      axios.post(`https://tower.future-swift.com/api/update_sec_des.php`, formData)
      .then(res => {
        setLoading(false);
        alert(res.data.msg);
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };
  
  



  const del1 = () => {
   
    setLoading(true);
    try {
    
  
      var formData = new FormData();
      formData.append("username",auth().username); 
  formData.append("key",auth().key); 

      formData.append("ad_id",a_des.ad_id); 
    
      axios.post(`https://tower.future-swift.com/api/delete_sec_des.php`, formData)
      .then(res => {
        setLoading(false);
        alert(res.data.msg);
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };
  
  



      const del = () => {
   
          if(window.confirm(" هل تريد تأكيد عملية الحذف")){
            del1();
          }
      
      };
      
      


   
const options =[

  {value:"1", label:"شمال"},
  {value:"2", label:"جنوب"},
  {value:"3", label:"شرق"},
  {value:"4", label:"غرب"},
  {value:"5", label:"شمال شرق"},
  {value:"6", label:"شمال غرب"},
  {value:"7", label:"جنوب شرق"},
  {value:"8", label:"جنوب غرب"},
  {value:"9", label:"شرقي شمال "},
  {value:"10", label:"غربي شمال"},
  {value:"11", label:"شرقي جنوبي"},
  {value:"12", label:"غربي جنوبي"},

];


return (
  
  <div>


  {loading ? (
    <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
      ) : (  
        
        
        
        
        a_des.ad_id > 0 ?
        
       
        <div  className="list" style={{marginTop:90,marginBottom:50}}>


     
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     
<div dir='' class="form-group">


    <p class="title">مخطط رقم {params.num} </p>

     
        <hr></hr>
      
        <label for="" class="form-label"> صورة المخطط : <br></br>
    <a href={'https://tower.future-swift.com/api/plan_img/'+a_des.img} >
      <img className='utilities' src={'https://tower.future-swift.com/api/plan_img/'+a_des.img} alt="" /></a></label>
        
      <hr></hr>
      <form  onSubmit={update}>   
    <label> تحديث صورة المخطط  </label>
        <input class="input" type="file"  accept="image/*" id="formFileLg" onChange={setimgfile} />
        
 
        <div dir='rtl' class="form-group">
        <br />
      
        <DatalistInput
        style={{color:'#1a1a1a'}}
        dir='rtl'
            placeholder="Search and choose..."
            label=": الأتجاة"
            onSelect={(item) => onChangeValue(item.id)}
            items={  options.map(option => (
        
              { id: option.value, value:option.label }
              
              ))
        
            }
          />
        </div>
        
        <br />
        {options.map(option => (
              
              option.value === a_des.dir ?
                   
                <div> <small>الخيار السابق:</small>  <mark><small>&ensp;{option.label}</small> </mark></div>
              
                   :''
                    
                  ))}
                  <br />
 <Button variant="warning" type='submit' >Update</Button>


      


      <div dir='rtl' class="form-group">
         <hr></hr>
         
<label> مجموعة عناصر :
        <input type='text' readOnly value={design.map(option => ( option.d_id === a_des.d_id  ?  option.name :null))} />
</label>
        


         
        
          </div>







      <br /> <br />
  
     
  </form>



      </div>
     <div dir='ltr'>
      <button on onClick={del} class="delete-button">
  <svg class="delete-svgIcon" viewBox="0 0 448 512">
                    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"></path>
                  </svg>
</button>
</div>
      </div>
    
    </div>


    :
    
     
    <div className="list" style={{marginTop:90,marginBottom:50}}>
   
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     
     <div dir='' class="form-group">
     
     
         <p class="title">مخطط رقم {params.num} </p>
     
          
             <hr></hr>
<form onSubmit={add}>

   
    <label> صورة المخطط  </label>
 <input class="input" type="file" required accept="image/*"  id="formFileLg" onChange={setimgfile} />
        
 
   
 
 <div dir='rtl' class="form-group">
         <hr></hr>
   

          <DatalistInput
dir='ltr'
style={{color:'#1a1a1a'}}
label=":العناصر"
    placeholder="Search and choose..."
    onSelect={(item) => onChangeValue1(item.id)}
    items={  design.map(option => (

      { id: option.d_id, value:option.name }
      
      ))

    }
  />

<br></br>

         
        
          </div>

<Form.Group as={Col} controlId="formGrid">
<DatalistInput
style={{color:'#1a1a1a'}}
dir='ltr'
    placeholder="Search and choose..."
    label=": الأتجاة"
    onSelect={(item) => onChangeValue(item.id)}
    items={  options.map(option => (

      { id: option.value, value:option.label }
      
      ))

    }
  />
</Form.Group>

<br></br>

      <Button variant="primary" type="submit">Add</Button>
    
     
    </form>


    </div>
    
    </div>



    </div>







 )}
 

 

  </div>
  
)
  }

export default Apart_des;









