import React, {useEffect,useState } from 'react'
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import {useAuthUser} from 'react-auth-kit'

const Manage_buildings = () => {

  const auth = useAuthUser();  

  useEffect( () => {
    allproduct();

}, []); 




const [out, setOutput] = useState({

    name:'',
    address:'',
    location:'',
  });
  
  
  

const [project, setProject] = useState([]);

const [loading, setLoading] = useState(false);





const allproduct = async (eventKey) => {
  
  setLoading(true);
  try {


    
    axios.get('https://tower.future-swift.com/api/buildings.php')
    .then(res => {
      if(res.data.success === true ){
      setProject(res.data.productlist.productdata);
    
      }
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}




const onChangeValue = (e) => {
    setOutput({
      ...out,
      [e.target.name]:e.target.value
    });
  
    
  } 
  
  
  const [file,setFile] = useState("");
    
  const setimgfile = (e)=>{
    setFile(e.target.files[0])
  }
  
  
  const add = async(event) => {
    setLoading(true);
     
    try {
      event.preventDefault();
      event.persist();
  
      var formData = new FormData();
      formData.append("username",auth().username); 
      formData.append("key",auth().key); 
   
      formData.append("name",out.name); 
      formData.append("address",out.address); 
      formData.append("location",out.location); 
     // formData.append("floors",out.floors); 
     // formData.append("apart",out.apart); 
      formData.append("photo",file); 
      axios.post(`https://tower.future-swift.com/api/`, formData)
      .then(res => {
            setLoading(false);
        alert(res.data.msg);
        
        window.location.reload(false);
       
       })
    } catch (error) { throw error;}    
  
  };


const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);



return (
  
  <div >

  
 
    {loading ? (
   
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
 
      ) : ( 


<div>

<Modal  style={{color:'#999'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title >تعديل البرج</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
      
  <div className="list" >
    
  <div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     
     <p  class="title"> تعديل برج  </p>
      
         <hr></hr>

<Form dir="RTL"  style={{width:'90%'}} onSubmit={add}>
<div dir='' class="form-group">
     <Form.Group as={Col} controlId="formGridEmail">
       <Form.Label>اسم البرج</Form.Label>
       <Form.Control name='name' onChange={onChangeValue} required type="text" placeholder="اسم البرج" />
     </Form.Group>

  
   <Form.Group className="mb-3" controlId="formGridAddress1">
     <Form.Label>العنوان</Form.Label>
     <Form.Control name='address' onChange={onChangeValue} required placeholder="صنعاء - شارع حده ...الخ" />
   </Form.Group>
   <hr></hr>
   <Row className="mb-2">
   <Form.Group className="mb-3" controlId="formGridAddress2">
     <Form.Label>الموقع</Form.Label>
     <Form.Control name='location' onChange={onChangeValue} type='text' placeholder="رابط الموقع من  Google Map" />
   </Form.Group>
   <a target='_blank' href='https://maps.google.com/'><Button variant="outline-success" style={{width:"200px"}}>Google Map</Button></a>
 
</Row>
<hr></hr>
<Form.Group controlId="formFileSm" className="mb-3">
     <Form.Label>صورة المخطط</Form.Label>
     <Form.Control onChange={setimgfile} required type="file"  accept="image/*" size="sm" />
   </Form.Group>
   <hr></hr>
   <Button variant='warning' type="submit">
     تعديل
   </Button>
   </div>
</Form>
</div>
</div>
           
         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>

        </Modal.Footer>
      </Modal>


<h1> الأبراج </h1>

  <Table dir='rtl' responsive striped bordered hover variant="light">
    <thead>
    <tr>
   
  <th scope="col">رقم البرج</th>
  <th scope="col">اسم البرج</th>
    <th scope="col">الادوار</th>
    <th scope="col">شقق/الدور </th>
    <th scope="col">العنوان</th>
    <th scope="col">الموقع</th>
    <th scope="col">تعديل</th>
    <th scope="col">تاريخ الانشاء</th>
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
  {project.map((item,index)=>(
   
   

      

        <tr>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.floors}</td>
        <td>{item.apart_per_floor}</td>
        <td>{item.address}</td>
        <td> <a className="imglink1" title='Dashboard' target='_blank' href={item.location}>
 <img src=".././map.png" style={{width:'40px' }}/>  
 </a></td>
        <td><Button variant="outline-warning" onClick={handleShow}>
          تعديل
        </Button></td> 
        <td>{item.created}</td>
       </tr>
     
  

 

 
))}

</tbody>

</Table>
</div>
      )}
 
  
   </div>
)
  }

export default Manage_buildings;