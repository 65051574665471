import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {Link} from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import {useAuthUser} from 'react-auth-kit'

const Design = () => {
  const auth = useAuthUser();  


  useEffect( () => {

   
  

    
 
    allproduct();
   

}, []); 





const [project, setProject] = useState([]);








const [loading, setLoading] = useState(false);

const allproduct = async () => {
  

  try {
   

    setLoading(true);
 
    
    axios.get('https://tower.future-swift.com/api/design.php')
    .then(res => {
      
      setProject(res.data.productlist.productdata);
      setLoading(false);
    })
  } catch (error) { throw error;}    
 
}



const onChangeValue = (e) => {
 setName(e.target.value)


  
} 


const [name, setName] = useState('');
const product = async (id) => {
  
  setLoading(true);
  try {
   

   
 
    
    axios.get('https://tower.future-swift.com/api/design_name.php?d_id='+id)
    .then(res => {
      if(res.data.success === true){
        setName(res.data.name);
        setLoading(false);
      }else{
        alert(res.data.msg)
        setLoading(false);
      }
    
    })
  } catch (error) { throw error;}    
 
}

const update = async(id) => {
   
  setLoading(true);
  try {


    var formData = new FormData();

    formData.append("username",auth().username); 
    formData.append("key",auth().key); 
    
    formData.append("name",name);
    formData.append("d_id",id);

    axios.post(`https://tower.future-swift.com/api/edit_design.php`, formData)
    .then(res => {
     
      setLoading(false);
      alert(res.data.msg);
      
      window.location.reload(false);
     
      
     
     })
  } catch (error) { throw error;}    

};


const [d_id, setD_id] = useState('');
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = (id) => {

  if(window.confirm( ` هل تريد تعديل اسم المجموعة   `)) {

    setD_id(id)
    product(id)
    setShow(true);
   }
}


return (
  
  <div >


  
    {loading ? (
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
      ) : ( 



        <div>





       
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{color:'#999'}}>تعديل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
    

<div className="list">

   
    
<div dir='rtl' style={{width:'90% ', maxWidth:'1000px'}} class="form-container">
     

     
     
         <p  class="title">تعديل اسم المجموعة </p>
     
          
             <hr></hr>
<form onSubmit>


<div dir='' class="form-group">



<label htmlFor="_name">اسم المجموعة:</label>

  <input
      type="text"
      id="_name"
      name="name"
     value={name}
     onChange={onChangeValue}
      placeholder="Enter Name"
      autoComplete="off"
      required
    />
  
    <Button variant='warning' onClick={() => update(d_id)}>تعديل</Button>
   



    <br></br>

</div>







</form>
</div>

</div>

         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>










     
        <h1>مجموعات العناصر</h1>
        <Table  responsive striped bordered hover variant="light">
    <thead>
    <tr>
    <th scope="col">تعديل</th>
  <th scope="col">ادارة</th>
  <th scope="col">تاريخ الأنشاء</th>
    <th scope="col">الاسم</th>
    <th scope="col">رقم </th>
  
    
    
   
    
       
   
   
  
        </tr>
        </thead>
  <tbody>
  {project.map((item,index)=>(
   
   

      

        <tr>
       <td><Button variant="outline-warning" onClick={() =>handleShow((item.d_id))}>
       تعديل
      </Button></td> 
        <td><Link  to={`manage/${item.d_id}`} className="btn default-btn"> اداره </Link></td> 
        <td>{item.date}</td>
        <td>{item.name}</td>
        <td>{item.d_id}</td>
       
        
        
       </tr>
     
  

 

 
))}

</tbody>

  
</Table>
</div>


      )}

    <br></br>
  </div>
)
  }

export default Design;









