import React, {useEffect,useState } from 'react'
import axios from 'axios';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {useAuthUser} from 'react-auth-kit'
import { useNavigate } from "react-router-dom";
const Getbuildings = () => {

  
  const navigate = useNavigate();
  const auth = useAuthUser();  

  useEffect( () => {
    allproduct();

}, []); 





const [project, setProject] = useState([]);

const [loading, setLoading] = useState(false);





const allproduct = async (eventKey) => {
  
  setLoading(true);
  try {


    
    axios.get('https://tower.future-swift.com/api/buildings.php')
    .then(res => {
      if(res.data.success === true ){
        setProject(res.data.productlist.productdata);
        setLoading(false);
      }else{

        setLoading(false);
        if (window.confirm(" لا يوجد ابراج , هل تريد ان تقوم بأضافة برج ؟   ")) {
          navigate(`/addbuilding`);
        
        }else{
          navigate(`/`);
}
      }
     
    })
  } catch (error) { throw error;}    
 
}




return (
  
  <div >

  
 
    {loading ? (
   
      <div className="loader-container">
      
      <h4 style={{position:'absolute', top:'37%', color:'lightblue'}}>FUTURE SWIFT...</h4>
        <div class="spinner">
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
  <div></div>
</div>
        </div>
 
      ) : ( 




  <div className="list">
  
<img className='center1' style={{position:'fixed' , zIndex:0}} src='.././logo2.png' />
  {project.map((item,index)=>(

<div>


    <div  className="mycard"style={{zIndex:1}}>
  <div class="content">
  
    <div class="back">
      <div  class="back-content" >
     
<img style={{width:'200px' }} src={"./b1.png"}  />
       
      
        <p class="title_">
              <strong>{item.name}</strong>
            </p>
          
<div style={{width:'90%'}}>
            <ProgressBar style={{width:'100%', height:'20px'}} dir='ltr'>
<ProgressBar animated variant="danger" now={item.selled} label={`${item.selled}%`} key={3} />
<ProgressBar  animated variant="warning" now={item.selled1} label={`${item.selled1}%`} key={2} />
<ProgressBar animated variant="success" now={item.available} label={`${item.available}%`} key={1} />
</ProgressBar>

 </div>

      </div>
     
    </div>
    <div class="front" >
      
      <div class="img">
        <div class="circle">
        </div>
        <div class="circle" id="right">
        </div>
        <div class="circle" id="bottom">
        </div>
      </div>


   

      <div class="front-content" dir='rtl'>
    <a target='_blank' style={{position:'absolute',top:10,right:10}} title='Dashboard' href={item.location}><img src=".././map.png" style={{width:'24px'}}/> </a>

        <small class="badge">{item.address}</small>
        <div class="description">
          <div class="title_">
            <p class="title_">
              <strong>{item.name}</strong>
            </p>
            <svg fill-rule="nonzero" height="15px" width="15px" viewBox="0,0,256,256" xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg"><g  text-anchor="none" font-size="none" font-weight="none" font-family="none" stroke-dashoffset="0" stroke-dasharray="" stroke-miterlimit="10" stroke-linejoin="miter" stroke-linecap="butt" stroke-width="1" stroke="none" fill-rule="nonzero" fill="#20c997"><g transform="scale(8,8)"><path d="M25,27l-9,-6.75l-9,6.75v-23h18z"></path></g></g></svg>
          </div>
          <a class="Btn2" href={`building/${item.id}`}>
  
  <div class="sign2"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
 
  <div style={{marginRight:30}} class="text2">دخول</div>

</a>
<br></br>
          <p class="card-footer" dir='rtl'>
          {item.floors} دور &nbsp; | &nbsp; {item.apart_per_floor} شقق/دور   &nbsp; ({item.apart_per_floor*item.floors})
         <hr></hr>
       
         {item.available_count} متاح &nbsp; | &nbsp; {item.selled_count} مبيوع &nbsp; | &nbsp; {item.reserved_count} محجوز 
          </p>
        
        </div>
      
       
      </div>
    </div>
    
    
  </div>
  
</div>

 


<a class="Btn2" href={`building/${item.id}`}>
  
  <div class="sign2"><svg viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path></svg></div>
 
  <div style={{marginLeft:30}} class="text2">دخول</div>

</a>


<hr></hr>
<br></br>
</div>
))}
  </div>




      )}
 
  
   </div>
)
  }

export default Getbuildings;